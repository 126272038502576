import { FC } from "react";
import styles from "./tunel.module.css";
import celltest from "../../../../public/assest/celltest.jpg";

import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
const Tunel: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("homePage.fs5")}
        enTitle={"Tunel Detection"}
        des={t("homePage.fs5detail")}
        urlType={"TunelDetection"}
        switchShow={false}
        showWhichType={"TunelDetection"}
        imgTest={celltest}
      />
    </div>
  );
};

export default Tunel;
