import { FC, useEffect } from "react";
import styles from "./skin.module.css";
import biaopisunshangtest from "../../../../public/assest/biaopisunshangtest.jpg";
import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const Skin: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      // history.push(`${process.env.PUBLIC_URL}/login`);
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("homePage.fs3")}
        enTitle={"SkinInjury"}
        des={t("skin.des")}
        urlType={"SkinInjury"}
        switchShow={false}
        showWhichType={"SkinInjury"}
        imgTest={biaopisunshangtest}
      />
    </div>
  );
};

export default Skin;
