import { FC, useEffect } from "react";
import styles from "./boneJoints.module.css";

import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
import guguanjie from "../../../../public/assest/guguanjietest.jpg";
import { useHistory } from "react-router-dom";
const BoneJoints: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      // history.push(`${process.env.PUBLIC_URL}/login`);
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("Evaluation.title")}
        enTitle={"JointInflammation"}
        des={t("Evaluation.des")}
        switchShow={true}
        urlType={"JointInflammation"}
        showWhichType={"JointInflammation"}
        imgTest={guguanjie}
      />
    </div>
  );
};

export default BoneJoints;
