import React from "react";
import { Table as AntdTable } from "antd";
import { TableProps } from "antd/lib/table";
import "./style.css";
export const Table = <T extends {} = any>(props: TableProps<T>) => {
  return (
    <AntdTable
      {...props}
      rowClassName={(record, index) => {
        return index % 2 === 0 ? "" : "highlight";
      }}
    />
  );
};
