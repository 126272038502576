import { RC4, enc } from "crypto-js";

const KEY = "AI_SYSTEM";

/**
 * 对option进行aes加密，并base64编码
 * @param option
 * @param debug
 */
export const toBackendToken = (option: { [id: string]: any }) => {
  // console.log("toBackendToken-----------");
  // console.log(option);
  const optionNoNan = Object.keys(option)
    .filter(
      (key) =>
        (option as any)[key] !== undefined && (option as any)[key] !== null
    )
    .sort()
    .reduce((ac, key) => ({ ...ac, [key]: option[key] }), {});
  const optionStr = JSON.stringify(optionNoNan);
  const strWords = enc.Utf8.parse(optionStr);
  const keyWords = enc.Utf8.parse(KEY);
  const res = RC4.encrypt(strWords, keyWords).ciphertext.toString(enc.Base64);
  // console.log(res);
  // console.log("toBackendToken-----------");
  return res;
};
/**
 * 解密
 * @param option
 * @param debug
 */
export const tokenToFront = (token: string) => {
  // console.log("TokenFront-----------");
  // console.log(token);
  const text = RC4.decrypt(
    decodeURIComponent(token),
    enc.Utf8.parse(KEY)
  ).toString(enc.Utf8);
  return text;
};
