import { FC, useState, useEffect } from "react";

import styles from "./login.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { Input } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
import footer from "../../../../public/assest/footer.png";
import { message, Statistic } from "antd";
import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, Checkbox, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { from, lastValueFrom, of, switchMap } from "rxjs";
import axios from "axios";
import BASE_URL from "../../../utils/gobalData";
import { userSlice } from "../../../redux/login/slice";

import { getQcord } from "../../../redux/register/slice";
import { md5 } from "md5js";
import { toBackendToken } from "../../../utils/toBackendToken";
import dayjs from "dayjs";
import { useSelector } from "../../../redux/hooks";
import { getCodeSlice } from "../../../redux/getCode/slice";
const Login: FC = () => {
  const { Countdown } = Statistic;
  const [loginType, setLoginType] = useState<string>("account");

  const history = useHistory();
  const dispatch = useDispatch();

  // const { codeValue } = useSelector((s) => s.user);
  useEffect(() => {
    localStorage.clear();
    dispatch(getCodeSlice.actions.showGetCode(true));
  }, [dispatch]);

  const Demo = () => {
    const timeOut = () => {
      console.log("finished!");
      dispatch(getCodeSlice.actions.showGetCode(true));
    };
    const { showGetCode } = useSelector((s) => s.getCodeSlice);
    const deadline = Date.now() + 60000; // Moment is also OK
    const [form] = Form.useForm();
    const [codeValue, setTextValue] = useState("");
    const [autoLogin, setAutoLogin] = useState(false);
    const getLoginQcode = () => {
      const codeToken = toBackendToken({
        time: dayjs().valueOf(),
      });
      console.log(codeToken);

      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let phoneNumber = form.getFieldValue("phone");
      if (reg_tel.test(phoneNumber)) {
        dispatch(
          getQcord({
            phoneAndCode: codeToken,
            targetNum: phoneNumber,
            msgType: "login",
          })
        );
        dispatch(getCodeSlice.actions.showGetCode(false));
      } else {
        message.warning("请输入正确的手机号");
      }
    };
    const onFinish = async (values: any) => {
      console.log("Success:", values);
      if (loginType === "account") {
        await lastValueFrom(
          of(values).pipe(
            switchMap((values) => {
              return from(
                axios.post(`/user/login/accAndPwd`, {
                  password: md5(values.psd, 32).toUpperCase(),
                  account: values.username,
                  autoLogin: autoLogin,
                })
              );
            })
          )
        ).then((data) => {
          console.log(data);
          let res = data.data;
          if (res.code === "100") {
            message.error(`${res.operation.data.content}`);
          } else if (res.code === "200") {
            console.log(res.data.token);
            dispatch(userSlice.actions.token(res.data.token));
            localStorage.setItem("BeadrToken", res.data.token);
            if (autoLogin) {
              localStorage.setItem(
                "loginTime",
                JSON.stringify(dayjs().valueOf() + 5100000000)
              );
            } else {
              localStorage.setItem(
                "loginTime",
                JSON.stringify(dayjs().valueOf() + 34000000)
              );
            }
            dispatch(userSlice.actions.error(res));
            history.push(`${process.env.PUBLIC_URL}/routepages/Welcome`);
            window.location.reload();
          }
        });
      } else {
        await lastValueFrom(
          of(values).pipe(
            switchMap((values) => {
              return from(
                axios.post(`${BASE_URL}/user/login/phoneAndCode`, {
                  checkCode: values.qcord,
                  msgType: "login",
                  phone: values.phone,
                })
              );
            })
          )
        ).then((data) => {
          let res = data.data;
          if (res.code === "100") {
            console.log(res);
            message.error(`${res.operation.data.content}`);
          } else if (res.code === "200") {
            console.log(res.data.token);
            dispatch(userSlice.actions.token(res.data.token));
            localStorage.setItem("BeadrToken", res.data.token);
            localStorage.setItem(
              "loginTime",
              JSON.stringify(dayjs().valueOf() + 340000000)
            );
            history.push(`${process.env.PUBLIC_URL}/routepages/Welcome`);
            window.location.reload();
          }
        });
      }
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };

    // const validateMessages = {
    //   required: "${label} is required!",
    //   types: {
    //     email: "${label} is not a valid email!",
    //     number: "${label} is not a valid number!",
    //   },
    //   number: {
    //     range: "${label} must be between ${min} and ${max}",
    //   },
    // };

    return (
      <>
        <Form
          form={form}
          style={{ width: 330, position: "relative" }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className={styles.formLogo}>
            <img src={footer} alt="" />
          </div>
          <div className={styles.loginType}>
            <div
              onClick={() => {
                setLoginType("account");
              }}
              className={
                loginType === "account"
                  ? styles.loginOptionsSelected
                  : styles.loginBase
              }
            >
              密码登录
            </div>
            <div
              onClick={() => {
                setLoginType("phone");
              }}
              className={
                loginType === "phone"
                  ? styles.loginOptionsSelected
                  : styles.loginBase
              }
            >
              手机号登录
            </div>
          </div>
          {loginType === "account" ? (
            <>
              <Form.Item
                style={{ width: "100%" }}
                name="username"
                rules={[{ required: true, message: "请输入您的手机号" }]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{ color: "#006FFF" }}
                      className="site-form-item-icon"
                    />
                  }
                  placeholder="请输入您的手机号"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name="psd"
                rules={[{ required: true, message: "请输入您的密码" }]}
              >
                <Input
                  prefix={
                    <LockOutlined
                      style={{ color: "#006FFF" }}
                      className="site-form-item-icon"
                    />
                  }
                  type="password"
                  placeholder="请输入您的密码"
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                style={{ width: "100%" }}
                name="phone"
                rules={[
                  { required: true, message: "请输入11位手机号" },
                  { min: 11, max: 11, message: "您输入的手机号码不正确" },
                ]}
              >
                <Input
                  value={codeValue}
                  onChange={(e) => {
                    // dispatch(userSlice.actions.codeValue(e.target.value));
                    setTextValue(e.target.value);
                  }}
                  prefix={
                    <MobileOutlined
                      style={{ color: "#006FFF" }}
                      className="site-form-item-icon"
                    />
                  }
                  placeholder="11位手机号"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: -10 }}>
                <Row gutter={8}>
                  <Col span={16}>
                    <Form.Item
                      name="qcord"
                      rules={[
                        {
                          required: true,
                          message: "请输入验证码!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <MailOutlined
                            style={{ color: "#006FFF" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder="请输入验证码"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Button style={{ width: "100%" }}>
                      {showGetCode ? (
                        <span onClick={getLoginQcode}> 获取验证码</span>
                      ) : (
                        <Countdown
                          value={deadline}
                          onFinish={timeOut}
                          format=" s "
                          valueStyle={{
                            width: "100%",
                            fontSize: 15,
                          }}
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}

          <Form.Item
            name="remember"
            valuePropName="checked"
            style={{
              width: "100%",
              marginBottom: -10,
            }}
          >
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item>
                  {loginType === "account" ? (
                    <Checkbox
                      onClick={() => {
                        setAutoLogin(true);
                      }}
                    >
                      自动登录
                    </Checkbox>
                  ) : null}
                </Form.Item>
              </Col>
              <Col style={{ textAlign: "right" }} span={8}>
                <span
                  className={styles.login}
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/forgetPsd`);
                  }}
                >
                  忘记密码
                </span>
              </Col>
            </Row>
          </Form.Item>

          <Button
            className={styles.register}
            style={{
              background: "#006fff",
              width: "100%",
              marginTop: -20,
            }}
            type="primary"
            htmlType="submit"
          >
            登录
          </Button>
          <Form.Item
            className={styles.loginRegister}
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Row gutter={8}>
              <Col style={{ textAlign: "right" }} span={24}>
                <span
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/register`);
                  }}
                  className={styles.login}
                >
                  注册账户
                </span>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </>
    );
  };
  return (
    <div className={styles.page}>
      <div className={styles.form}>
        <Demo />
      </div>
    </div>
  );
};

export default Login;
