import { FC } from "react";

import styles from "./home.module.css";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Layout, Breadcrumb } from "antd";
import Header from "../../../component/Header";
import Page from "../../page1/component/home";
import Page2 from "../../page2/component/home";
import Page3 from "../../page3/component/page3";
import UserCenter from "../../userCenter/component/userCenter";
import HistoryResult from "../../historyResult/component/historyResult";

import CellFusion from "../../cellFusion/compoment/cellFusion";
import Skin from "../../skin/compoment/skin";
import IL6 from "../../il6/compoment/il6";
import Tunel from "../../tunel/compoment/tunel";
import Masson from "../../masson/compoment/masson";

import NoFound from "../../errorPage/compoment/noFound";
import { useTranslation } from "react-i18next";
import { imgFcSlice } from "../../../redux/imgFunction/slice";
import { useDispatch } from "react-redux";
import BoneJoints from "../../boneJoints";
import UserAgreement from "../../userAgreement/component/userAgreement";
import VertebraeDensity from "../../VertebraeDensity/compoment/vertebraeDensity";
import BoneDensity from "../../BoneDensity/compoment/boneDensity";
import Gristle from "../../gristle/compoment/gristle";
import { useSelector } from "../../../redux/hooks";
const Home: FC = () => {
  const { Content, Footer } = Layout;
  const { t } = useTranslation();
  const breadcrumbNameMap = {
    "/home": `${t("header.home")}`,
    // "/home/page1": `${t("header.page1")}`,
    // "/home/page2": `${t("header.page2")}`,
    // "/home/page3": `${t("header.home")}`,
    "/home/userCenter": `${t("header.usercenter")}`,
    "/home/historyResult": `${t("header.history")}`,
    "/home/Confluency": `${t("homePage.Degree_of_cell_fusion")}`,
    "/home/JointInflammation": `${t("homePage.Evaluation_of_osteoarthrosis")}`,
    "/home/SkinInjury": `${t("homePage.fs3")}`,
    "/home/IL6Detection": `${t("homePage.fs4")}`,
    "/home/TunelDetection": `${t("homePage.fs5")}`,
    "/home/MassonDetection": `${t("homePage.fs6")}`,
    "/home/BoneDensity": `${t("homePage.fs7")}`,
    "/home/VertebraeDensity": `${t("homePage.fs8")}`,
    "/home/CartilageThickness": `${t("homePage.fs9")}`,
    "/home/userAgreement": `${t("homePage.useragreement")}`,
  };
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // taskIdRedux,
    // type,
    // loading,
    reset,
  } = useSelector((s) => s.imgFcSlice);
  const pathSnippets = location.pathname
    .split("/")
    .filter((i) => i)
    .splice(1, location.pathname.split("/").filter((i) => i).length);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log(pathSnippets.splice(1, pathSnippets.length));

            history.push(`${process.env.PUBLIC_URL}${url}`);
            // window.location.reload();
            dispatch(imgFcSlice.actions.setNeedClear(true));
            dispatch(imgFcSlice.actions.reset(!reset));
          }}
        >
          {breadcrumbNameMap[url]}
        </span>
      </Breadcrumb.Item>
    );
  });

  return (
    <>
      <Layout className={styles["layout"]}>
        <Header />
<div 
         className={styles.routePagesHtml}>

 <Content
            style={{
              background: "#fff",
              // background: "red",
              flex: " 0 0 auto",
            }}
          >
            <div
              style={{
                padding: "16px 0px",
                width: "1200px",
                margin: "0 auto",
              }}
            >
              <Breadcrumb
                style={{
                  marginLeft: "24px",
                  background: "#fff",
                }}
              >
                {extraBreadcrumbItems}
              </Breadcrumb>
            </div>
          </Content>
          <Content
            style={{
              padding: "24px 0 50px ",
              minWidth: "1200px",
              background: "#F2F8FF",
              // height: "100%",
            }}
          >
            <div className={styles["site-layout-content"]}>
              {/* <div className={styles.content}> */}
              <Switch>
                {/* <Route exact path="/home/friend" component={Frined} /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/home/page1`}
                  component={Page}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/page2`}
                  component={Page2}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/page3`}
                  component={Page3}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/Confluency`}
                  component={CellFusion}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/JointInflammation`}
                  component={BoneJoints}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/SkinInjury`}
                  component={Skin}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/IL6Detection`}
                  component={IL6}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/TunelDetection`}
                  component={Tunel}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/MassonDetection`}
                  component={Masson}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/BoneDensity`}
                  component={BoneDensity}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/CartilageThickness`}
                  component={Gristle}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/VertebraeDensity`}
                  component={VertebraeDensity}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/userCenter`}
                  component={UserCenter}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/historyResult`}
                  component={HistoryResult}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/home/userAgreement`}
                  component={UserAgreement}
                />
                <Route component={NoFound} />
              </Switch>
              {/* </div> */}
            </div>
          </Content>

        <Footer style={{ textAlign: "center", minWidth: "1200px" }}>
          {/* {window.location.href.indexOf("/home/userAgreement") !== -1 ? ( */}
          {/* <>
            {" "}
            <div className={styles.footer}>
              <img src={footer} alt="" />
              <div className={styles.footerBotUser}>
                <div>{t("homePage.copyright")}</div>{" "}
                <div>{t("homePage.icp")}</div> <div>{t("homePage.police")}</div>
              </div>
            </div>
          </> */}
          {/* ) : ( */}
          <div className={styles.footer}>
            <div className={styles.footerTop}>
              <div
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/home/userAgreement`);
                }}
                className={styles.footer1}
              >
                {t("homePage.useragreement")}
              </div>{" "}
              <div
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/home/userAgreement`);
                }}
                className={styles.footerTopCenter}
              >
                {t("homePage.Privacypolicy")}
              </div>{" "}
              <div
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/home/userAgreement`);
                }}
                className={styles.footer1}
              >
                {t("homePage.callus")}
              </div>
            </div>
            <div className={styles.footerBot}>
              <div className={styles.footer1}>{t("homePage.copyright")}</div>{" "}
              <div onClick={() => {
                window.open(`https://beian.miit.gov.cn/#/Integrated/index`)
              }} className={styles.footerTopCenter}>{t("homePage.icp")}</div>{" "}
              <div onClick={() => {
                window.open(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000677`)
              }} className={styles.footer1}>{t("homePage.police")}</div>
            </div>
          </div>
          {/* )} */}

          {/* <img className={styles.footerImg} src={footer} alt="" /> */}
        </Footer>

</div>
         
      </Layout>
    </>
  );
};

export default Home;
