import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import axios from "axios";
import BASE_URL from "../../utils/gobalData";
// import cookie from "react-cookies";
interface imgFcState {
  fileName: any;
  ruleType: any;
  url: any;
  content: any;
  status: any;
  result: any;
  creationDate: any;
  loading: boolean;
  taskIdRedux: any;
  type: any;
  needClear: boolean;
  top: any;
  buttom: any;
  gotoHistory: boolean;
  reset: boolean;
}

const initialState: imgFcState = {
  fileName: "",
  ruleType: "",
  url: "",
  content: "",
  status: "",
  result: "",
  creationDate: "",
  loading: true,
  taskIdRedux: "",
  type: "",
  needClear: true,
  top: 0.9,
  buttom: 0.25,
  gotoHistory: false,
  reset: false,
};
export const start = createAsyncThunk(
  "/task",
  async (
    paramaters: {
      fileNames?: any;
      formParams?: any;
      imageUrls?: any;
      type?: any;
      formImageObjects?: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`${BASE_URL}/task`, {
      formParams: paramaters.formParams,
      formImageObjects: paramaters.formImageObjects,
      type: paramaters.type,
    });
    return data.data;
  }
);
export const jointInflammationTask = createAsyncThunk(
  "/task/jointInflammationTask",
  async (
    paramaters: {
      distanceToContour: any;
      hogLowerBound: any;
      hogUpperBound: any;
      regionRatio: any;
      taskType: any;
      formImageObjects: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/jointInflammationTask`, {
      distanceToContour: paramaters.distanceToContour,
      formImageObjects: paramaters.formImageObjects,
      hogLowerBound: paramaters.hogLowerBound,
      hogUpperBound: paramaters.hogUpperBound,
      regionRatio: paramaters.regionRatio,
      taskType: paramaters.taskType,
    });
    return data;
  }
);
export const skinInjuryTask = createAsyncThunk(
  "/task/skinInjuryTask",
  async (
    paramaters: {
      formImageObjects: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/skinInjuryTask`, {
      formImageObjects: paramaters.formImageObjects,
    });
    return data;
  }
);
export const massonDetectionTask = createAsyncThunk(
  "/task/massonDetectionTask",
  async (
    paramaters: {
      formImageObjects: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/massonDetectionTask`, {
      formImageObjects: paramaters.formImageObjects,
    });
    return data;
  }
);
export const il6DetectionTask = createAsyncThunk(
  "/task/il6DetectionTask",
  async (
    paramaters: {
      imageDAPI: any;
      imageSpGreen: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/il6DetectionTask`, {
      imageDAPI: paramaters.imageDAPI,
      imageSpGreen: paramaters.imageSpGreen,
    });
    return data;
  }
);
export const tunelDetectionTask = createAsyncThunk(
  "/task/tunelDetectionTask",
  async (
    paramaters: {
      imageDAPI: any;
      imageSpGreen: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/tunelDetectionTask`, {
      imageDAPI: paramaters.imageDAPI,
      imageSpGreen: paramaters.imageSpGreen,
    });
    return data;
  }
);
export const boneDensityTask = createAsyncThunk(
  "/task/boneDensityTask",
  async (
    paramaters: {
      url: any;
      name: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/boneDensityTask`, {
      url: paramaters.url,
      name: paramaters.name,
    });
    return data;
  }
);
export const vertebraeDensityTask = createAsyncThunk(
  "/task/vertebraeDensityTask",
  async (
    paramaters: {
      url: any;
      name: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/vertebraeDensityTask`, {
      url: paramaters.url,
      name: paramaters.name,
    });
    return data;
  }
);

export const cartilageThicknessTask = createAsyncThunk(
  "/task/cartilage-thickness-task/create",
  async (
    paramaters: {
      url: any;
      name: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/task/cartilage-thickness-task/create`, {
      url: paramaters.url,
      name: paramaters.name,
    });
    return data;
  }
);
export const feedback = createAsyncThunk(
  "/feedback/save",
  async (
    paramaters: {
      accuracyType: any;
      content?: any;
      taskId: any;
    },
    thunkAPI
  ) => {
    // 测试用的taskid
    // 8475e53b-52dd-44ab-b6d4-210203a15bf5
    const { data } = await axios.post(`/feedback/save`, {
      accuracyType: paramaters.accuracyType,
      content: paramaters.content,
      taskId: paramaters.taskId,
    });

    return data.data;
  }
);
export const imgFcSlice = createSlice({
  name: "imgFc",
  initialState,
  reducers: {
    reset: (s, a) => {
      s.reset = a.payload;
    },
    init: (s, a) => {
      s.fileName = a.payload;
      s.ruleType = a.payload;
      s.url = a.payload;
      s.content = a.payload;
      s.ruleType = a.payload;
      s.taskIdRedux = a.payload;
    },
    setGotoHistory: (s, a) => {
      s.gotoHistory = a.payload;
    },
    setNeedClear: (s, a) => {
      s.needClear = a.payload;
    },
    creationDate: (s, a) => {
      s.creationDate = a.payload;
    },
    status: (s, a) => {
      s.status = a.payload;
    },
    result: (s, a) => {
      s.result = a.payload;
    },
    afterfeedback: (s, a) => {
      s.content = a.payload;
    },
    content: (s, a) => {
      s.content = a.payload;
    },
    fileName: (s, a) => {
      s.fileName = a.payload;
    },
    ruleType: (s, a) => {
      s.ruleType = a.payload;
    },
    url: (s, a) => {
      s.url = a.payload;
    },
    loading: (s, a) => {
      s.loading = a.payload;
    },
    setTop: (s, a) => {
      s.top = a.payload;
    },
    setButtom: (s, a) => {
      s.buttom = a.payload;
    },
  },
  extraReducers: {
    [feedback.fulfilled.type]: (s, a) => {
      message.success("反馈提交成功！");
    },
    [start.pending.type]: (s, a) => {
      s.loading = true;
    },
    [start.fulfilled.type]: (s, a) => {
      // s.taskIdRedux = a.payload.taskId;
      s.type = a.payload.type;
      if (a.payload.taskId) {
        s.gotoHistory = true;
      }
      console.log(a.payload);
    },
    [start.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },
    [jointInflammationTask.pending.type]: (s, a) => {
      s.loading = true;
    },
    [jointInflammationTask.fulfilled.type]: (s, a) => {
      if (a.payload.code === "100") {
        message.error(`${a.payload.operation.data.content}`);
      } else {
        s.taskIdRedux = a.payload.taskId;
        s.gotoHistory = true;
        s.type = "JointInflammation";
        s.loading = false;
        console.log(a.payload);
      }
    },
    [jointInflammationTask.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },

    [skinInjuryTask.pending.type]: (s, a) => {
      s.loading = true;
    },
    [skinInjuryTask.fulfilled.type]: (s, a) => {
      if (a.payload.code === "100") {
        message.error(`${a.payload.operation.data.content}`);
      } else {
        s.taskIdRedux = a.payload.taskId;
        s.gotoHistory = true;
        s.type = "SkinInjury";
        s.loading = false;
        console.log(a.payload);
      }
    },
    [skinInjuryTask.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },

    [massonDetectionTask.pending.type]: (s, a) => {
      s.loading = true;
    },
    [massonDetectionTask.fulfilled.type]: (s, a) => {
      if (a.payload.code === "100") {
        message.error(`${a.payload.operation.data.content}`);
      } else {
        s.taskIdRedux = a.payload.taskId;
        s.gotoHistory = true;
        s.type = "MassonDetection";
        s.loading = false;
        console.log(a.payload);
      }
    },
    [massonDetectionTask.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },

    [il6DetectionTask.pending.type]: (s, a) => {
      s.loading = true;
    },
    [il6DetectionTask.fulfilled.type]: (s, a) => {
      if (a.payload.code === "100") {
        message.error(`${a.payload.operation.data.content}`);
      } else {
        s.taskIdRedux = a.payload.taskId;
        s.gotoHistory = true;
        s.type = "IL6Detection";
        s.loading = false;
        console.log(a.payload);
      }
    },
    [il6DetectionTask.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },

    [tunelDetectionTask.pending.type]: (s, a) => {
      s.loading = true;
      console.log(a);
    },
    [tunelDetectionTask.fulfilled.type]: (s, a) => {
      if (a.payload.code === "100") {
        message.error(`${a.payload.operation.data.content}`);
      } else {
        s.taskIdRedux = a.payload.taskId;
        s.gotoHistory = true;
        s.type = "TunelDetection";
        s.loading = false;
        console.log(a.payload);
      }
    },
    [tunelDetectionTask.rejected.type]: (s, a) => {
      console.log(a.payload);
      s.loading = false;
    },
  },
});
