import { useDispatch } from "react-redux";
import { forwardRef, useImperativeHandle, useState } from "react";
import { getQcord } from "../../../redux/register/slice";
import { useHistory } from "react-router";
import { Form, Input, Row, Col, Button, message, Statistic } from "antd";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";

import axios from "axios";
import BASE_URL from "../../../utils/gobalData";
import { toBackendToken } from "../../../utils/toBackendToken";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const FormDemo = ({ type, form }, ref: any) => {
  const { Countdown } = Statistic;
  const deadline = Date.now() + 60000; // Moment is also OK
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showGetInitPhoneCode, setShowGetInitPhoneCode] = useState(true);
  const [showGetNewPhoneCode, setShowGetNewPhoneCode] = useState(true);
  const timeOut = () => {
    console.log("finished!");
    setShowGetInitPhoneCode(true);
  };
  const newPhoneTimeOut = () => {
    console.log("finished!");
    setShowGetNewPhoneCode(true);
  };
  useImperativeHandle(ref, () => ({
    getUserNameValue: () => {
      return {
        userName: form.getFieldValue("username"),
      };
    },
    getPsdValue: () => {
      return {
        initPassword: form.getFieldValue("initPassword"),
        password: form.getFieldValue("password"),
      };
    },

    getEmailValue: () => {
      return {
        userName: form.getFieldValue("username"),
      };
    },
    getMobileValue: () => {
      return {
        phone: form.getFieldValue("phone"),
        initPhone: form.getFieldValue("initPhone"),
        phoneQcord: form.getFieldValue("qcord"),
        initPhoneQcord: form.getFieldValue("initQcord"),
      };
    },

    getOrganizationValue: () => {
      return {
        userName: form.getFieldValue("institution"),
      };
    },
  }));

  let reg_tel =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  let re =
    /^([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    let value = form.getFieldsValue();
    of(value)
      .pipe(
        switchMap(() =>
          axios.post(`${BASE_URL}/user/register`, {
            checkCode: value.qcord,
            email: value.email,
            mobile: value.phone,
            organizationName: value.institution,
            password: value.password,
            userName: value.username,
          })
        )
      )
      .toPromise()
      // 使用lastvalueform代替
      .then((data) => {
        let res = data?.data;
        if (res.code === "200") {
          message.success("注册成功");
          history.push(`${process.env.PUBLIC_URL}/login`);
        } else {
          message.error(res.operation.data["content"]);
        }
      });
  };
  const getCord = () => {
    const codeToken = toBackendToken({
      time: dayjs().valueOf(),
    });
    if (type === "邮箱") {
      if (re.test(form.getFieldValue("email"))) {
        dispatch(
          getQcord({
            phoneAndCode: codeToken,
            targetNum: form.getFieldValue("phone"),
            msgType: "register",
          })
        );
      } else {
        message.warning(`${t("userCenter.emailNewCk")}`);
      }
    } else if (type === "手机号") {
      if (reg_tel.test(form.getFieldValue("phone"))) {
        dispatch(
          getQcord({
            phoneAndCode: codeToken,
            targetNum: form.getFieldValue("phone"),
            msgType: "bindNewPhone",
          })
        );
        setShowGetNewPhoneCode(false);
      } else {
        message.warning(`${t("userCenter.phoneIntCk")}`);
      }
    }
  };
  const getInitQcode = () => {
    const codeToken = toBackendToken({
      time: dayjs().valueOf(),
    });
    if (reg_tel.test(form.getFieldValue("initPhone"))) {
      dispatch(
        getQcord({
          phoneAndCode: codeToken,
          targetNum: form.getFieldValue("initPhone"),
          msgType: "changePhone",
        })
      );
      setShowGetInitPhoneCode(false);
    } else {
      message.warning(`${t("userCenter.phoneIntCk")}`);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      style={{ width: 330, position: "relative" }}
      name="basic"
      initialValues={{
        remember: false,
        prefix: "86",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {type === "邮箱" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="initEmail"
          rules={[
            { required: true, message: `${t("userCenter.emailInt")}` },
            { type: "email", message: `${t("userCenter.emailNewCk")}` },
          ]}
        >
          <Input placeholder={t("userCenter.emailInt")} />
        </Form.Item>
      ) : null}
      {type === "邮箱" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="email"
          rules={[
            { required: true, message: `${t("userCenter.emailNewInput")}` },
            { type: "email", message: `${t("userCenter.emailNewCk")}` },
          ]}
        >
          <Input placeholder={t("userCenter.emailNewInput")} />
        </Form.Item>
      ) : null}

      {/* 修改密码 */}
      {type === "密码" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="initPassword"
          rules={[
            { required: true, message: `${t("userCenter.psdIntCk")}` },
            { min: 6, max: 16, message: `${t("userCenter.psdIntCks")}` },
          ]}
        >
          <Input type="password" placeholder={t("userCenter.psdIntCk")} />
        </Form.Item>
      ) : null}
      {type === "密码" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="password"
          rules={[
            { required: true, message: `${t("userCenter.psdnewCk")}` },
            { min: 6, max: 16, message: `${t("userCenter.psdIntCks")}` },
          ]}
        >
          <Input type="password" placeholder={t("userCenter.psdnewCk")} />
        </Form.Item>
      ) : null}
      {type === "密码" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%", marginBottom: -2 }}
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: `${t("userCenter.confirmPsd")}`,
            },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${t("userCenter.noyiyang")}`));
              },
            }),
          ]}
        >
          <Input type="password" placeholder={t("userCenter.confirmPsd")} />
        </Form.Item>
      ) : null}

      {/* 修改手机号 */}
      {type === "手机号" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="initPhone"
          rules={[
            { required: true, message: `${t("userCenter.phoneInt")}` },
            { pattern: reg_tel, message: `${t("userCenter.phoneIntCk")}` },
            // { min: 11, max: 11, message: "请输入11位手机号" },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     if (reg_tel.test(value)) {
            //       return Promise.resolve();
            //     }
            //     return Promise.reject(new Error("请输入正确的手机号"));
            //   },
            // }),
          ]}
        >
          <Input placeholder={t("userCenter.phoneIntPla")} />
        </Form.Item>
      ) : null}
      {type === "手机号" ? (
        <Form.Item style={{ marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                colon={false}
                label=" "
                name="initQcord"
                rules={[
                  {
                    required: true,
                    message: `${t("userCenter.qcodeIpu")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button style={{ width: "100%" }}>
                {showGetInitPhoneCode ? (
                  <>
                    <span onClick={getInitQcode}>
                      {" "}
                      {t("userCenter.getCode")}
                    </span>
                  </>
                ) : (
                  <Countdown
                    value={deadline}
                    onFinish={timeOut}
                    format=" s "
                    valueStyle={{
                      width: "100%",
                      fontSize: 15,
                    }}
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      ) : null}
      {type === "手机号" ? (
        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="phone"
          rules={[
            { required: true, message: `${t("userCenter.PhoneSet")}` },
            { pattern: reg_tel, message: `${t("userCenter.phoneIntCk")}` },
          ]}
        >
          <Input placeholder={t("userCenter.phoneNew")} />
        </Form.Item>
      ) : null}
      {type === "手机号" || type === "邮箱" ? (
        <Form.Item style={{ marginBottom: -20 }}>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                colon={false}
                label=" "
                name="qcord"
                rules={[
                  {
                    required: true,
                    message: `${t("userCenter.qcodeIpu")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              {type === "手机号" ? (
                <Button style={{ width: "100%" }}>
                  {showGetNewPhoneCode ? (
                    <>
                      <span onClick={getCord}>{t("userCenter.getCode")}</span>
                    </>
                  ) : (
                    <Countdown
                      value={deadline}
                      onFinish={newPhoneTimeOut}
                      format=" s "
                      valueStyle={{
                        width: "100%",
                        fontSize: 15,
                      }}
                    />
                  )}
                </Button>
              ) : null}
              {type === "邮箱" ? (
                <Button onClick={getCord}>{t("userCenter.getCode")}</Button>
              ) : null}
            </Col>
          </Row>
        </Form.Item>
      ) : null}

      {/* 修改机构 */}
      {type === "机构" ? (
        <Form.Item
          style={{ width: "100%", marginBottom: -20 }}
          name="institution"
          rules={[{ required: true, message: `${t("userCenter.orgs")}` }]}
        >
          <Input placeholder={t("userCenter.orgs")} />
        </Form.Item>
      ) : null}
      {type === "用户名" ? (
        <Form.Item
          style={{ width: "100%", marginBottom: -20 }}
          name="username"
          rules={[{ required: true, message: `${t("userCenter.userCk")}` }]}
        >
          <Input placeholder={t("userCenter.userCk")} />
        </Form.Item>
      ) : null}
    </Form>
  );
};
// return <Demo />;
// };

// export default FormDemo;
export default forwardRef(FormDemo);
