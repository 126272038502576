import { FC, useEffect } from "react";
import styles from "./boneDensity.module.css";
import jingguest from "../../../../public/assest/jingguest.jpg";

import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const BoneDensity: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      // history.push(`${process.env.PUBLIC_URL}/login`);
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("homePage.fs7")}
        enTitle={"BoneDensity"}
        des={t("homePage.fs7renwu")}
        urlType={"BoneDensity"}
        switchShow={false}
        showWhichType={"BoneDensity"}
        imgTest={jingguest}
      />
    </div>
  );
};

export default BoneDensity;
