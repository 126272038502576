import { FC, useState } from "react";

import styles from "./userAgreement.module.css";

import { Content } from "antd/lib/layout/layout";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const UserAgreement: FC = () => {
  const [first, setFirst] = useState<boolean>(true);
  const [second, setSecond] = useState<boolean>(false);
  const [third, setThird] = useState<boolean>(false);
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  const SmallTitle = ({ label }) => {
    return <div className={styles.smalltitle}>{label}</div>;
  };
  return (
    <div className={styles.page}>
      <Content className={styles.content}>
        <div className={styles.contentLeft}>
          <div
            onClick={() => {
              setSecond(false);
              setThird(false);
              setFirst(true);
            }}
            className={
              first ? styles.contentLeftFirstActive : styles.contentLeftFirst
            }
          >
            {t("homePage.useragreement")}
          </div>
          <div
            onClick={() => {
              setFirst(false);
              setSecond(true);
              setThird(false);
            }}
            className={
              second ? styles.contentLeftFirstActive : styles.contentLeftFirst
            }
          >
            {t("homePage.Privacypolicy")}
          </div>
          <div
            onClick={() => {
              setFirst(false);
              setSecond(false);
              setThird(true);
            }}
            className={
              third ? styles.contentLeftFirstActive : styles.contentLeftFirst
            }
          >
            {t("homePage.callus")}
          </div>
        </div>
        {first ? (
          <div className={styles.firstContent}>
            {" "}
            <Title level={4}> {t("homePage.useragreement")}</Title>
            <Paragraph>
              请您在开始使用辅助读片系统服务前，请您务必仔细阅读并充分理解本《用户服务协议》。在本服务条款中，请您务必特别注意及重点阅读与您的权利及义务密切相关的条款。除非您已经阅读并接受本协议所有条款，否则你无权使用本辅助读片系统服务。您的注册账号、登录、使用服务等行为即视为您已阅读并同意上述协议的约束。
            </Paragraph>
            <SmallTitle label="一、协议的范围" />
            <Paragraph>
              本协议是您与服务的提供者浙江超级灵魂人工智能研究院有限公司（以下简称“超级灵魂”）之间关于您使用本协议下辅助读片系统相关服务所订立的协议。
            </Paragraph>
            <SmallTitle label="二、协议内容及生效" />
            <Paragraph>
              本协议的服务内容是指浙江超级灵魂人工智能研究院有限公司向用户提供的辅助读片系统，支持细胞融合度及计数、骨关节炎症检测、表皮损伤检测、IL6检测、TUNEL检测、MASSON染色体检测、表皮厚度检测等在内的服务。
              <br /> <br />
              为使您更好的使用本系统的相关服务，请您仔细阅读以下条款。您勾选“同意”后，本协议即刻生效，对双方产生约束力。
            </Paragraph>
            <SmallTitle label="三、本服务许可的范围" />
            <Paragraph>
              超级灵魂给予您一项个人的、不可转让及非排他性的许可，以使用本协议下的服务。您可以为非商业目的在单一台终端设备上使用本协议下的服务，且本服务所产生的任何信息仅用于科学研究。
              <br />
              <br />
              本协议其他条款未明示授权的其他一切权利仍由超级灵魂保留，您在行使这些权利时须另外取得超级灵魂的书面许可。超级灵魂如果未行使前述任何权利，并不构成对该权利的放弃。
            </Paragraph>
            <SmallTitle label="四、权利义务条款" />
            <Paragraph>
              1、注册者资格
              <br />
              您在申请使用服务时必须同时满足以下条件： <br />
              （1）您在注册或实际使用平台服务时，应当是具备完全民事权利能力和完全民事行为能力的自然人；
              <br />
              （2）您在注册账号时，必须填写个人真实信息。
              <br />
              <br />
              2、您在注册完成账号后，请妥善保存登录账号和密码，在任何情况下，您都不向他人透露登录账号及密码。您的账号不得以任何方式转让、赠与或继承。如果如您发现您的账号或密码发生泄露时，请您立即联络超级灵魂，以便采取相应合理措施。
              <br />
              <br />{" "}
              3、您在使用本服务时须遵守国家法律、法规、行业准则等规范性文件及平台的各项规则和要求，不得违背社会公共利益和公序良俗，不得损害他人的合法权益，不得违反本协议及相关规则。
              <br />
              <br />
              特别的，超级灵魂并不会对您上传的图片进行审核，您同意您上传的图片不得违反国家法律法规规定的内容，具体包括：
              <br />
              （1）违反宪法确定的基本原则的；
              <br />
              （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
              <br />
              （3）损害国家荣誉和利益的；
              <br />
              （4）煽动民族仇恨、民族歧视，破坏民族团结的；
              <br />
              （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
              <br />
              （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
              <br />
              （7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
              <br />
              （8）侮辱或者诽谤他人，侵害他人合法权益的；
              <br />
              （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
              <br />
              （10）以非法民间组织名义活动的；
              <br />
              （12）含有法律、行政法规禁止的其他内容的。
              <br />
              <br />
              您同意，您所在上传的图片不存在侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；不涉及他人隐私、个人信息或资料；不涉及骚扰、广告信息、过度营销信息或任何暗示性信息的；不涉及其他干扰正常服务和侵犯其他第三方合法权益的信息的。
              <br />
              <br />
              您同意，我们会对您上传的图片进行（不进行）保密。
              <br />
              <br />
              4、您已充分知晓，您使用辅助读片系统服务所产生信息，基于现有科技水平，超级灵魂并不对此信息的准确性负责，该信息仅具有参考意义。
              <br />
              <br />{" "}
              5、超级灵魂有义务在现有技术条件下维护整个辅助读片系统的正常运行，并努力提升和改进技术，使对您的服务得以顺利进行。
              <br />
              <br />
              6、超级灵魂有权对用户的注册数据及服务行为进行查阅，发现注册数据或服务行为中存在任何问题或存在合理怀疑，有权向用户发出询问或要求改正的通知，或者根据实际情况直接作出删除等处理。
              <br />
              <br />{" "}
              7、超级灵魂有义务保护用户的个人信息及隐私信息（需要公布在平台中的资料和信息除外）的安全，不得泄露用户的隐私。具体可参考相关《隐私政策》。
            </Paragraph>
            <SmallTitle label="五、违约处理" />
            1.您理解并同意：如果超级灵魂查阅、收到他人举报或投诉用户违反本协议约定的，有权在不通知用户的情况下，随时对相关内容进行删除、屏蔽，并视行为情节对违规主体相关帐号进行处理，包括但不限于限制或禁止使用部分或全部功能、帐号封禁直至注销。
            <br />
            <br />{" "}
            2.您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、诉讼等，您应当独立承担责任；若超级灵魂因此遭受损失的，您也应当赔偿。
            <br />
            <br /> <SmallTitle label="六、责任限制" />
            1、超级灵魂不对您在本服务中相关数据的删除或储存失败负责。
            <br />
            <br />{" "}
            2、您理解并同意：对于因不可抗力包括但不限于互联网连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、国际和国内外法院的命令或第三方的不作为，导致本服务中断、本服务无法使用或您的权益遭受侵害，超级灵魂不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
            <br />
            <br /> <SmallTitle label="七、知识产权条款" />
            您已知晓，超级灵魂是本服务的知识产权权利人。本服务下的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本服务相关的所有信息内容，超级灵魂享有上述全部的知识产权。
            <br />
            <br /> <SmallTitle label="八、其他" />
            1.超级灵魂有权在必要时修改本协议条款。您可以随时查阅相关协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本软件。
            <br />
            <br /> 2.本协议签订地为中华人民共和国浙江省德清县。
            <br />
            <br />{" "}
            3.本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。
            <br />
            <br />{" "}
            4.如果您和超级灵魂之间发生任何纠纷或争议，应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
            <br />
            <br />{" "}
            5.本协议无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。{" "}
            <br />
            <br /> <br />
            <br /> <br />
            <br />
          </div>
        ) : (
          <>
            {second ? (
              <div className={styles.firstContent}>
                <Title level={4}> {t("homePage.Privacypolicy")}</Title>
                <SmallTitle label="1.总则" />
                <Paragraph>
                  1.1
                  我们重视用户的隐私。在您使用我们的服务时，我们会严格按照相关法律法规及本辅助读片系统隐私政策（下称“本隐私政策”）的规定收集、使用、储存和共享（以下通称“处理”）您的个人信息。本隐私政策适用于我们向您提供的服务。
                  <br /> 1.2
                  在使用我们的服务前，请务必仔细阅读并充分理解本隐私政策。您使用或继续使用我们的服务，即意味着您已知晓本隐私政策的全部条款并且完全同意我们按照本隐私政策处理您的相关信息。
                </Paragraph>
                <SmallTitle label="2. 定义" />
                <Paragraph>
                  2.1
                  您：指使用我们产品或服务的个人，包括以法人、其他组织名义使用我们产品或服务的个人。
                  <br /> 2.2
                  我们：指服务的提供者浙江超级灵魂人工智能研究院有限公司（简称“超级灵魂”）。
                  <br /> 2.3
                  服务：指我们通过包括但不限于电脑、移动智能终端等渠道提供的辅助读片等服务。
                  <br /> 2.4
                  个人信息：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、身份证件号码、通信通讯联系方式、账号密码等。{" "}
                </Paragraph>
                <SmallTitle label="3. 信息的收集和使用" />{" "}
                <Paragraph>
                  3.1
                  我们提供服务时，可能会收集和使用与您有关的信息。如果您不提供相关信息，可能无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
                  <br /> 3.2
                  在您使用我们的服务过程中，我们仅会在非常必要的情况下和非常有限的限度内收集您的个人信息，并且这些信息仅用于向您提供服务、改进以及优化我们的服务，以及保障您的账号安全。具体而言，我们将按照如下方式收集您主动提供或因为使用产品和服务而产生的信息：
                  <br /> 3.2.1
                  在您对我们的服务进行使用之前，首先需要注册您的账号，您需要向我们提供您的电子邮箱地址、手机号码等信息（以下统称“基础信息”），用于确定您的用户信息。
                  <br /> 3.2.3
                  我们可能会使用您的基础信息向您推送问卷调查等信息。如果您不想接收这些信息，可在收到上述邮件后，随时按邮件说明退订。
                  <br /> 3.2.4
                  当您与我们联系或我们主动通过问卷调查、电话征求您的使用建议或意见时，我们可能会保存您的通信、通话记录和内容等信息，该等信息用于帮助我们改进和优化服务问题。除非相关法律另有规定外，这些信息不应被视为具有保密性。
                  <br /> 3.2.5
                  我们会使用您上传的图片、读片结果、用户反馈等信息以及基于我们服务所产生的信息用于改进以及优化我们的服务。同时，为了确保服务的安全，帮助我们更好地了解我们网站的运行情况，我们可能记录相关信息，例如，您使用该服务的频率、崩溃数据、总体使用情况等。
                  <br />{" "}
                  3.2.6当您使用我们的产品或服务时，您的某些信息，包括但不限于您使用设备的型号、唯一标识号、操作系统、浏览器类型、显示器分辨率、互联网协议地址、域名、网页、访问网站时间和时长、使用服务时所访问的页面，可能会通过互联网协议地址、cookies、网址标签以及导航数据采集等各种技术和方法被采集（即在您非主动提供信息的情况下进行收集）并记入服务器日志文件。我们可能会使用这些信息作数据统计分析，以便改进我们的服务和营销。这些信息通常不属于可以识别用户身份的个人信息，但如果将这些非个人信息与其他信息结合可以用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这些信息可以被视为个人信息的话，我们会在提供或使用时将其做匿名化、去标识化处理，以保护您的个人信息不被滥用。{" "}
                </Paragraph>
                <SmallTitle label="4. 信息安全" />{" "}
                <Paragraph>
                  我们非常重视您的个人信息安全，我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。
                  <br /> 4.1
                  我们建立专门的管理制度、操作流程、组织等管理措施以保障信息的安全。例如，我们严格限制接触该用户信息的人员范围，并要求他们承担保密义务。
                  <br /> 4.2
                  若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并依法履行相关的报告及通知义务。
                  <br /> 4.3
                  请您务必注意，您不得向他人泄露登录账号和密码，如您发现自己的个人信息尤其是您的账号或密码发生泄露时，请您立即联络我们，以便我们根据您的申请采取相应合理措施。
                  <br /> 4.4
                  请您理解，我们会尽力保护您的个人信息，但是没有任何网站、Internet
                  传输、计算机系统或无线连接是绝对安全的。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将根据法律规定承担相应的责任。{" "}
                </Paragraph>
                <SmallTitle label="5. 信息的存储" />{" "}
                <Paragraph>
                  5.1
                  存储地域范围：我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。
                  <br /> 5.2
                  存储期限：一般而言，我们仅为实现本隐私政策所述目的所必需的时间保留您的个人信息，除非法律要求或允许可以在更长时间内保留这些信息。{" "}
                </Paragraph>
                <SmallTitle label="6.信息的共享" />{" "}
                <Paragraph>
                  6.1
                  您使用我们服务的相关信息可能会与我们的关联方或合作方共享。我们会要求关联方或合作方遵守严格的保密规定，以不低于我们的安全措施标准来处理您的个人信息。
                  <br /> 6.2
                  根据相关法律法规及国家标准，以下情形中，我们可能会处理你的相关个人信息而无需征求你的授权同意：
                  <br /> 6.2.1 为个人信息处理者履行法定义务或法定职责所必需；
                  6.2.2 为订立、履行你作为一方当事人的合同所必需； 6.2.3
                  因法律法规、政府命令、强制披露、应对突发公共卫生事件或者紧急情况下为保护自然人的生命健康和财产安全所必需；
                  <br /> 6.2.4
                  依法在合理的范围内处理你自行公开或者其他已经合法公开的个人信息；
                  <br /> 6.2.5 法律、行政法规规定的其他情形。{" "}
                </Paragraph>
                <SmallTitle label="7. 信息的管理" />{" "}
                <Paragraph>
                  7.1
                  您可以通过登录您在我们网站注册的账号来查看、更改提交给我们的个人信息、我们所提供的服务信息等。请您及时更新相关信息，以确保它们是准确、最新且完整的。
                  <br /> 7.2
                  如果您提出注销账号的申请，我们将根据您的意愿和法律规定将您的个人信息删除或进行匿名化处理。{" "}
                </Paragraph>
                <SmallTitle label="8. 未成年人隐私保护" />{" "}
                <Paragraph>
                  8.1
                  如果您是18周岁以下的未成年人，在使用我们的服务前，应事先取得您的家长或法定监护人的书面同意。
                  <br /> 8.2
                  对于经父母或监护人同意使用我们的服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享或披露信息。{" "}
                </Paragraph>
                <SmallTitle label="9. 责任限制" />{" "}
                <Paragraph>
                  9.1
                  您同意：由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险由您个人承担。
                  <br /> 9.2
                  您同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断，我们不承担任因此可能给您导致的损失，但将尽力减少因此给您造成的损失和影响。
                  <br />{" "}
                  不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴、瘟疫等以及社会事件如战争、动乱、政府行为。{" "}
                </Paragraph>
                <SmallTitle label="10. 本隐私政策的变更和通知" />{" "}
                <Paragraph>
                  由于服务内容、联络方式、法律和监管政策要求等变化，我们保留本隐私政策进行变更、修改的权利。当隐私政策发生变更时，我们会在我们的官方网站、移动端发布或向您提供的电子邮箱地址发送更新后的版本。{" "}
                </Paragraph>
                <SmallTitle label="11. 联系我们" />{" "}
                <Paragraph>
                  在本隐私政策提及的所有可能需要与我们取得联系的情况下，您可以通过以下方式联系我们：
                  <br /> 电子邮件：support@supersoulai.com{" "}
                </Paragraph>
              </div>
            ) : (
              <div className={styles.firstContent}>
                <Title level={4}> {t("homePage.callus")}</Title>
                <SmallTitle label="邮箱：support@supersoulai.com" />
                <br /> <br /> <br /> <br /> <br /> <br /> <br />
                <br /> <br /> <br /> <br /> <br /> <br /> <br />
                <br /> <br /> <br /> <br /> <br /> <br /> <br />
              </div>
            )}
          </>
        )}
      </Content>
    </div>
  );
};

export default UserAgreement;
