import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  Modal,
  Pagination,
  Form,
  Select,
  Input,
  Radio,
  message,
} from "antd";
import { FC } from "react";
import styled from "styled-components";
import styles from "./historyResult.module.css";
import zh_CN from "antd/es/locale/zh_CN";
import en_US from "antd/lib/locale/en_US";
import { Table } from "./Table/Table";

// import { useForm } from "antd/es/form/Form";
import { useHistory } from "react-router-dom";
import { getResultList } from "../../../redux/historyResult/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../redux/hooks";
import { formatTime } from "../../../utils/timeHelper";
import { feedback } from "../../../redux/imgFunction/slice";
import { getUserCenterInfo } from "../../../redux/userCenter/slice";

import { useTranslation } from "react-i18next";
const { TextArea } = Input;

const TitleDiv = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 45px;
  text-align: left;
  font-weight: 500;
`;
const FootDiv = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
`;

const HistoryResult: FC = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [radiuValue, setradiuValue] = useState("accurate");
  const [addTaskForm] = Form.useForm();
  const [feedbackForm] = Form.useForm();
  const { resultList, total, size, current } = useSelector(
    (s) => s.historyResult
  );
  const { languageCode } = useSelector((s) => s.HeaderSlice);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      message.info(`登录验证已过期，请重新登录`);
      setTimeout(() => {
        // history.push(`${process.env.PUBLIC_URL}/login`);
        history.push("/");
        window.open(`${process.env.PUBLIC_URL}/login`);
      }, 2000);
    }
  }, [history]);
  useEffect(() => {
    dispatch(getUserCenterInfo());
  }, [dispatch]);
  const { userName } = useSelector((s) => s.userCenter);
  const fuhao = () => {
    if (languageCode === "zh") {
      return "；";
    } else {
      return ";";
    }
  };
  // const [pageSize, setPageSize] = useState(size);

  const getType = (text) => {
    if (text === "CellConfluencyAndCounting") {
      return `${t("homePage.Degree_of_cell_fusion")}`;
    } else if (text === "JointInflammation") {
      return `${t("history.type2")}`;
    } else if (text === "SkinInjury") {
      return `${t("homePage.fs3")}`;
    } else if (text === "IL6Detection") {
      return `${t("homePage.fs4")}`;
    } else if (text === "TunelDetection") {
      return `${t("homePage.fs5")}`;
    } else if (text === "MassonDetection") {
      return `${t("homePage.fs6")}`;
    } else if (text === "BoneDensity") {
      return `${t("homePage.fs7")}`;
    } else if (text === "VertebraeDensity") {
      return `${t("homePage.fs8")}`;
    }else if (text === "CartilageThickness") {
      return `${t("homePage.fs9")}`;
    } else {
      return null;
    }
  };
  const col = [
    {
      title: `${t("history.num")}`,
      dataIndex: "taskId",
      key: "taskId",
      width: "15%",
    },
    {
      title: `${t("history.type")}`,
      dataIndex: "type",
      key: "type",
      width: "17%",
      render: (type) => (
        <>
          <div>{getType(type)}</div>
        </>
      ),
    },
    {
      title: `${t("history.res")}`,
      dataIndex: "result",
      key: "result",
      render: (text, status) => (
        <>
          <div
            onClick={() => {
              console.log(JSON.parse(text));
              console.log(status);
            }}
          >
            {status.type === "CellConfluencyAndCounting" ? (
              <>
                {t("history.data2")}
                {text ? JSON.parse(text).Counts : "-"}
                {fuhao()}
                {t("history.data1")}
                {text ? JSON.parse(text).Confluency : "-"}
              </>
            ) : null}{" "}
            {status.type === "JointInflammation" ? (
              <>
                {t("history.yanzhengchengdu")}
                {text ? JSON.parse(text).InflammationRatio : "-"} {fuhao()}
                {JSON.parse(status.formParams).taskType === "1" ? (
                  <>
                    {" "}
                    {t("history.spots1")}
                    {text
                      ? JSON.parse(text).WeightedInflammationRatio1
                      : "-"}{" "}
                    {fuhao()}
                    {t("history.spots2")}
                    {text
                      ? JSON.parse(text).WeightedInflammationRatio2
                      : "-"}{" "}
                    {fuhao()}
                  </>
                ) : null}
              </>
            ) : null}
            {/* 表皮损伤的参数 */}
            {status.type === "SkinInjury" ? (
              <>
                {t("cell.redness")}
                {text ? JSON.parse(text).Redness : "-"} {fuhao()}
                {t("cell.damage")}
                {text ? JSON.parse(text).Damage : "-"} {fuhao()}
              </>
            ) : null}
            {/* il6和tunel的参数 */}
            {status.type === "IL6Detection" ||
            status.type === "TunelDetection" ? (
              <>
                {t("cell.numPerArea")}：
                {text ? JSON.parse(text).NumPerArea : "-"} {fuhao()}
                {t("cell.intensityPerArea")}：
                {text ? JSON.parse(text).StrengthPerArea : "-"}；
                {t("cell.perNum")}：{text ? JSON.parse(text).Num : "-"}；
                {t("cell.preIntensity")}：
                {text ? JSON.parse(text).Strength : "-"}
              </>
            ) : null}
            {/* masson的参数 */}
            {status.type === "MassonDetection" ? (
              <>
                {t("cell.bluenessProportation")}：
                {text ? JSON.parse(text).BluenessIntensity : "-"}；
                {t("cell.bluenessIntensity")}：
                {text ? JSON.parse(text).BluenessProportation : "-"}；
              </>
            ) : null}
            {status.type === "BoneDensity" ? (
              <>
                {t("cell.Spongyratio")}
                {text ? JSON.parse(text).SpongyRatio : "-"} {fuhao()}
                {t("cell.CorticalRatio")}
                {text ? JSON.parse(text).CorticalRatio : "-"} {fuhao()}
              </>
            ) : null}
            {status.type === "VertebraeDensity" ? (
              <>
                {t("cell.Spongyratio")}
                {text ? JSON.parse(text).SpongyRatio : "-"}
              </>
            ) : null}
            {status.type === "CartilageThickness" ? (
              <>
                {t("cell.CartilageThickness")}
              </>
            ) : null}
          </div>
        </>
      ),
    },
    {
      title: `${t("history.status")}`,
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record) => (
        <>
          {text === "completed" ? (
            <div className={styles.textHeight}>
              <div style={{ background: "#52c41a" }} />
              {t("history.state1")}
            </div>
          ) : (
            <div className={styles.textHeight}>
              <div style={{ background: "#ffa940" }} />
              {text === "calculating" && t("history.state3")}
              {text === "error" && t("history.state2")}
            </div>
          )}
        </>
      ),
    },
    {
      title: `${t("history.time")}`,
      dataIndex: "lastUpdateDate",
      key: "lastUpdateDate",
      width: "15%",
    },
    {
      title: `${t("history.action")}`,
      dataIndex: "operate",
      key: "operate",
      width: "10%",
      render: (text, record) => (
        <>
          {record.status !== "error" ? (
            <div
              className={styles.sucOperate}
              onClick={() => {
                if (record.type === "CellConfluencyAndCounting") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/Confluency?taskId=${record.taskId}&type=${record.type}`
                  );
                } else if (record.type === "JointInflammation") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/JointInflammation?taskId=${record.taskId}&type=${record.type}`
                  );
                } else if (record.type === "SkinInjury") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/SkinInjury?taskId=${record.taskId}&type=${record.type}`
                  );
                } else if (record.type === "BoneDensity") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/BoneDensity?taskId=${record.taskId}&type=${record.type}`
                  );
                } else if (record.type === "VertebraeDensity") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/VertebraeDensity?taskId=${record.taskId}&type=${record.type}`
                  );
                }else if (record.type === "CartilageThickness") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/CartilageThickness?taskId=${record.taskId}&type=${record.type}`
                  );
                }
                // else if (record.type === "IL6Detection") {
                //   history.push(
                //     `${process.env.PUBLIC_URL}/home/IL6Detection?taskId=${record.taskId}&type=${record.type}`
                //   );
                // } else if (record.type === "TunelDetection") {
                //   history.push(
                //     `${process.env.PUBLIC_URL}/home/TunelDetection?taskId=${record.taskId}&type=${record.type}`
                //   );
                // } else if (record.type === "MassonDetection") {
                //   history.push(
                //     `${process.env.PUBLIC_URL}/home/MassonDetection?taskId=${record.taskId}&type=${record.type}`
                //   );
                // }
              }}
            >
              {t("history.action1")}
            </div>
          ) : (
            <div className={styles.failOperate}> {t("history.action1")}</div>
          )}
        </>
      ),
    },
  ];
  const tableData = useMemo(
    () =>
      resultList.map((node) => {
        return {
          key: node.taskId,
          taskId: node.taskId,
          type: node.type,
          result: node.result,
          status: node.status,
          formParams: node.formParams,
          lastUpdateDate: formatTime(node.lastUpdateDate),
        };
      }),
    [resultList]
  );

  useEffect(() => {
    dispatch(getResultList({ current: current, size: size }));
  }, [current, dispatch, size]);
  // useEffect(() => {
  //   dispatch(getResultList({ current: current, size: size }));
  // }, []);
  const handleOk = () => {
    feedbackForm
      .validateFields()
      .then((values) => {
        console.log(values);
        feedbackForm.resetFields();
        setIsModalVisible(false);
        dispatch(
          feedback({
            accuracyType: values.accuracyTypes,
            content: values.content,
            taskId: values.taskId,
          })
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    feedbackForm.resetFields();
  };
  const onChangeRadiu = (e) => {
    console.log("radio checked", e.target.value);
    setradiuValue(e.target.value);
  };
  const addTask = () => {
    addTaskForm
      .validateFields()
      .then((values) => {
        console.log(values);
        addTaskForm.resetFields();
        setIsAddModalVisible(false);
        values.selectFun === "Confluency" &&
          history.push(`${process.env.PUBLIC_URL}/home/Confluency`);
        values.selectFun === "JointInflammation" &&
          history.push(`${process.env.PUBLIC_URL}/home/JointInflammation`);
        values.selectFun === "SkinInjury" &&
          history.push(`${process.env.PUBLIC_URL}/home/SkinInjury`);
        values.selectFun === "IL6Detection" &&
          history.push(`${process.env.PUBLIC_URL}/home/IL6Detection`);
        values.selectFun === "TunelDetection" &&
          history.push(`${process.env.PUBLIC_URL}/home/TunelDetection`);
        values.selectFun === "MassonDetection" &&
          history.push(`${process.env.PUBLIC_URL}/home/MassonDetection`);
        values.selectFun === "BoneDensity" &&
          history.push(`${process.env.PUBLIC_URL}/home/BoneDensity`);
        values.selectFun === "VertebraeDensity" &&
          history.push(`${process.env.PUBLIC_URL}/home/VertebraeDensity`);
        values.selectFun === "CartilageThickness" &&
          history.push(`${process.env.PUBLIC_URL}/home/CartilageThickness`);
        // window.location.reload();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onShowSizeChange = (current, size) => {
    // setPageSize(size);
  };
  const onChangePage = (page, pageSize) => {
    dispatch(getResultList({ current: page, size: pageSize }));
  };

  return (
    <>
      {languageCode === "zh" ? (
        <TitleDiv>{userName}，你好</TitleDiv>
      ) : (
        <TitleDiv>Welcome,{userName}!</TitleDiv>
      )}

      <Button
        style={{
          background: "#006fff",
          fontFamily: "PingFangSC-Regular, PingFang SC",
          padding: "4px 16px",
          margin: "32px 0 16px",
        }}
        type="primary"
        onClick={() => setIsAddModalVisible(true)}
      >
        + {t("history.add")}
      </Button>
      <div className={styles.tableContent}>
        <Table columns={col} dataSource={tableData} pagination={false}></Table>
      </div>
      <FootDiv>
        <div className={styles.text}>
          {t("history.reportNo")}&nbsp;&nbsp;
          <span
            className={styles.feedback}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {t("history.report")}
          </span>
        </div>
        <ConfigProvider locale={languageCode === "zh" ? zh_CN : en_US}>
          <Pagination
            defaultCurrent={1}
            total={total}
            showQuickJumper
            showSizeChanger
            current={current}
            pageSize={size}
            pageSizeOptions={["1", "10", "20", "30"]}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </ConfigProvider>
      </FootDiv>

      <ConfigProvider locale={languageCode === "zh" ? zh_CN : en_US}>
        <Modal
          title={t("history.report")}
          visible={isModalVisible}
          wrapClassName={styles.modalDiv}
          style={{ top: 218 }}
          width={720}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={feedbackForm}
            name="feedbackForm"
            initialValues={{ accuracyTypes: radiuValue }}
          >
            <Form.Item
              label={t("history.num")}
              name="taskId"
              rules={[{ required: true, message: `${t("history.selNum")}` }]}
            >
              <Select style={{ width: 294 }} placeholder={t("history.selNum")}>
                {resultList.map((res) => {
                  return (
                    <Select.Option value={res.taskId}>
                      {res.taskId}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("history.exact")}
              name="accuracyTypes"
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={onChangeRadiu}>
                <Radio value="accurate">{t("history.exact1")}</Radio>
                <Radio value="uncertain">{t("history.exact2")}</Radio>
                <Radio value="inaccurate">{t("history.exact3")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("history.reportCon")}
              name="content"
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: `${t("history.reRul")}` }]}
            >
              <TextArea
                placeholder={t("history.reportDes")}
                showCount
                maxLength={300}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={t("history.add")}
          visible={isAddModalVisible}
          wrapClassName={styles.modalDiv}
          style={{ top: 218 }}
          width={720}
          onOk={addTask}
          onCancel={() => {
            setIsAddModalVisible(false);
            addTaskForm.resetFields();
          }}
          forceRender={true}
        >
          <Form form={addTaskForm} name="addTaskForm">
            <Form.Item
              label={t("history.seLFc")}
              name="selectFun"
              rules={[{ required: true, message: `${t("history.seLFc")}` }]}
            >
              <Select style={{ width: 294 }} placeholder={t("history.seLFc")}>
                <Select.Option value="Confluency">
                  {t("function.title")}
                </Select.Option>
                <Select.Option value="JointInflammation">
                  {t("history.type2")}
                </Select.Option>
                <Select.Option value="SkinInjury">
                  {t("homePage.fs3")}
                </Select.Option>
                <Select.Option value="BoneDensity">
                  {t("homePage.fs7")}
                </Select.Option>
                <Select.Option value="VertebraeDensity">
                  {t("homePage.fs8")}
                </Select.Option>
                <Select.Option value="CartilageThickness">
                  {t("homePage.fs9")}
                </Select.Option>
                {/* <Select.Option value="IL6Detection">
                  {t("homePage.fs4")}
                </Select.Option>
                <Select.Option value="TunelDetection">
                  {t("homePage.fs5")}
                </Select.Option>
                <Select.Option value="MassonDetection">
                  {t("homePage.fs6")}
                </Select.Option> */}
                {/* <Select.Option value="demo2">Demo2</Select.Option> */}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default HistoryResult;
