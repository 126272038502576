import { createSlice } from "@reduxjs/toolkit";

// import cookie from "react-cookies";
interface getCodeState {
  showGetCode: boolean;
}

const initialState: getCodeState = {
  showGetCode: true,
};

export const getCodeSlice = createSlice({
  name: "getCode",
  initialState,
  reducers: {
    showGetCode: (s, a) => {
      s.showGetCode = a.payload;
    },
  },
});
