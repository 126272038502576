import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import BASE_URL from "../../utils/gobalData";
import axios from "axios";

interface RegisterState {
  show: boolean;
  psd: any;
  registerData: any;
}

const initialState: RegisterState = {
  show: false,
  psd: "",
  registerData: "",
};
export const register = createAsyncThunk(
  "user/register",
  async (
    paramaters: {
      checkCode: string;
      email: string;
      mobile: string;
      organizationName: string;
      password: string;
      userName: string;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`${BASE_URL}/user/register`, {
      checkCode: paramaters.checkCode,
      email: paramaters.email,
      mobile: paramaters.mobile,
      organizationName: paramaters.organizationName,
      password: paramaters.password,
      userName: paramaters.userName,
    });
    return data;
  }
);
export const getQcord = createAsyncThunk(
  "user/getQcord",
  async (
    paramaters: {
      phoneAndCode: string;
      targetNum: string;
      msgType: string;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(
      `${BASE_URL}/sms/phoneCode`,
      {
        msgType: paramaters.msgType,
        targetNum: paramaters.targetNum,
      },
      {
        headers: {
          "X-RequestToken": paramaters.phoneAndCode,
        },
      }
    );
    return data;
  }
);
export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerData: (s, a) => {
      s.registerData = a.payload;
    },
    psd: (s, a) => {
      s.psd = a.payload;
    },
  },
  extraReducers: {
    [getQcord.pending.type]: (state, action) => {
      // message.success("发送成功");
      // console.log(action.payload);
    },
    [getQcord.fulfilled.type]: (state, action) => {
      console.log(action.payload);
      let res = action.payload;
      if (res.code === "100") {
        message.error(res.operation.data.content);
      } else if (res.code === "200") {
        message.success("发送成功");
      }
    },
    [getQcord.rejected.type]: (state, action) => {
      // message.error(action.payload);
      // console.log(action.payload);
    },
    [register.pending.type]: (state, action) => {},
    [register.fulfilled.type]: (state, action) => {},
    [register.rejected.type]: (state, action) => {},
  },
});
