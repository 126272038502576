import { FC, useEffect } from "react";
import styles from "./cellFusion.module.css";
import celltest from "../../../../public/assest/celltest.jpg";
import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const CellFusion: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("function.title")}
        enTitle={"Cell Confluency and Counting"}
        des={t("function.des")}
        urlType={"Confluency"}
        switchShow={false}
        showWhichType={"CellConfluencyAndCounting"}
        imgTest={celltest}
        // testImgs={celltest}
      />
    </div>
  );
};

export default CellFusion;
