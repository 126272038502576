import { useState, useEffect } from "react";
import styles from "./imgFunction.module.css";
// import { useDispatch } from "react-redux";
import tiffNo from "../../../../public/assest/tiffNo.png";
import {
  Button,
  Upload,
  message,
  Radio,
  Input,
  Spin,
  Modal,
  Slider,
  Switch,
} from "antd";
// import { SearchOutlined } from "@ant-design/icons";
// import exifr from "exifr/dist/lite.umd.js";
import { Typography, Select, Image } from "antd";
import ziprar from "../../../../public/assest/ziprar.png";

// import { from, lastValueFrom, of, switchMap } from "rxjs";
// import axios from "axios";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import BASE_URL from "../../../utils/gobalData";
import { useTranslation } from "react-i18next";
import {
  imgFcSlice,
  start,
  feedback,
  jointInflammationTask,
  skinInjuryTask,
  massonDetectionTask,
  il6DetectionTask,
  tunelDetectionTask,
  boneDensityTask,
  vertebraeDensityTask,
  cartilageThicknessTask,
} from "../../../redux/imgFunction/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../redux/hooks";

import { from, lastValueFrom, of, switchMap } from "rxjs";
import axios from "axios";
import { formatTime } from "../../../utils/timeHelper";
import { useHistory, useLocation } from "react-router";

import dayjs from "dayjs";
import { IconFont } from "../../../utils/iconfont/icon";
import { setTimeout } from "timers";
import { userSlice } from "../../../redux/login/slice";

const { Dragger } = Upload;

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export const Mask = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.mask1}>
        <div>
          <Spin />
          &nbsp; {t("cell.Calculating")}
        </div>
      </div>
    </>
  );
};

// switchshow 控制批量开关显示与否
const ImgFunction = ({
  title,
  enTitle,
  des,
  switchShow,
  urlType,
  showWhichType,
  imgTest,
}) => {
  const { t } = useTranslation();
  const marksarearatio = {
    0: "0",
    0.33: "0.33",
    1: "1",
  };
  const marksdistance = {
    0: "0",
    6.0: "6.0",
    10: "10",
  };
  const marksHOGbuttomHOGtop = {
    0: "0",
    0.25: "0.25",
    0.9: "0.9",
    1: "1",
  };
  const {
    // taskIdRedux,
    // type,
    // loading,
    content,
    fileName,
    ruleType,
    url,
    status,
    creationDate,
    top,
    buttom,
    gotoHistory,
    reset,
  } = useSelector((s) => s.imgFcSlice);

  const bonesList = [
    `${t("history.fileName")}`,
    `${t("history.arearatio")}`,
    `${t("history.distance")}`,
    `${t("cell.InflammatoryArticulation")}`,
    // `${t("history.HOGtop")}`,
  ];
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const taskId = new URLSearchParams(location.search).get("taskId");
  const typeHistory = new URLSearchParams(location.search).get("type");
  const [noTaskIdFileLists, setNoTaskIdFileLists] = useState<any[]>([]);
  const [canGetResult, setCangetResult] = useState<boolean>(false);
  const [moduleurl, setModuleUrl] = useState<any>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingValue, setLoading] = useState<boolean>(false);
  const [switchDisable, setSwitchDisable] = useState<boolean>(false);
  const [filesLists, setFileList] = useState<any>([]);
  const [showWhichPra, setShowWhichPra] = useState(showWhichType);
  const [infoFilelist, setInfoFileList] = useState<any>();
  // 批量的按钮的值
  const [showMore, setShow] = useState(false);
  // 填完参的值
  const [hasCalculate, setCalculate] = useState(false);
  // 计算中 蒙版
  const [isCalculating, setIsCalculating] = useState(false);
  // 左边图片
  const [rightUrl, setRightUrl] = useState("");
  // 右边图片
  const [leftUrl, setLeftUrl] = useState("");
  // 反馈中的选项
  const [radiuValue, setradiuValue] = useState(1);
  // 比例尺
  const [ruleTypeNumber, setRuleTypeNumber] = useState<string>("-1");
  // 得到的参数
  const [Confluency, setConfluency] = useState<any>("");
  const [Counts, setCounts] = useState<any>("");
  // 等待计算   点击开始计算会让这个变为true
  const [waitResult, setWaitResult] = useState<any>(false);
  // 骨关节参数
  // 区域比例
  const [arearatio, setAreaRatio] = useState<any>(0.33);
  // 与关节轮廓距离
  const [distance, setDistance] = useState<any>(6.0);
  // HOG下界
  const [HOGbuttom, setHOGbuttom] = useState<any>(0.25);
  // HOG上界
  const [HOGtop, setHOGtop] = useState<any>(0.9);
  // 炎症程度
  const [inflammation, setInflammation] = useState<any>("");
  // 斑点颜色加权占比1
  const [spots1, setSpots1] = useState<any>("");
  // 斑点颜色加权占比2
  const [spots2, setSpots2] = useState<any>("");
  // 表皮损伤
  // 红肿长度
  const [redness, setRedNess] = useState<any>("");
  // 损伤程度
  const [damage, setDamage] = useState<any>("");
  // Masson 染色检测
  const [bluenessProportation, setBluenessProportation] = useState<any>("");
  const [bluenessIntensity, setBluenessIntensity] = useState<any>();
  const [firstImg, setFirstImg] = useState<any>("");
  const [secondImg, setSecondImg] = useState<any>("");
  // Tunel参数
  const [numPerArea, setNumPerArea] = useState<any>("");
  const [intensityPerArea, setIntensityPerArea] = useState<any>("");
  const [perNum, setPerNum] = useState<any>("");
  const [preIntensity, setPreIntensity] = useState<any>("");
  const [spongyratiovalue, setSpongyratio] = useState<any>("");
  const [corticalRatiovalue, setCorticalRatio] = useState<any>("");
  const [example, setExample] = useState<boolean>(false);

  const [thickness, setThickness] = useState<any>([]);
  // const [needClear, setClear] = useState<boolean>(true);
  // const dsadsa =
  //   "https://ai-api.wolwobiotech.com/file/download?objectName=1639707392243lgMJ.tif";

  const clean = () => {
    dispatch(imgFcSlice.actions.init(""));
    dispatch(imgFcSlice.actions.loading(true));
    // dispatch(imgFcSlice.actions.setNeedClear(true))
    setWaitResult(false);
    setCalculate(false);
    setIsCalculating(false);
    setRightUrl("");
    setLeftUrl("");
    setRuleTypeNumber("-1");
    setAreaRatio(0.33);
    setDistance(6.0);
    setHOGbuttom(0.25);
    setHOGtop(0.9);
    setInflammation("");
    setSpots1("");
    setSpots2("");
    setSwitchDisable(false);
    setFileList([]);
    setInfoFileList("");
    setRedNess("");
    setDamage("");
    setBluenessIntensity("");
    setBluenessProportation("");
    setFirstImg("");
    setSecondImg("");
    setNumPerArea("");
    setIntensityPerArea("");
    setPerNum("");
    setPreIntensity("");
    setSpongyratio("");
    setThickness([]);
    setCorticalRatio("");
    setCangetResult(false);
    setNoTaskIdFileLists([]);
  };
  const getTaskResult = async (taskId: any) => {
    await lastValueFrom(
      of(taskId).pipe(
        switchMap((taskId) => {
          return from(axios.get(`/task/get/byTaskId?taskId=${taskId}`));
        })
      )
    ).then((data) => {
      let res = data.data;
      if (res.code === "100") {
        message.error(`${res.operation.data.content}`);
        dispatch(imgFcSlice.actions.loading(true));
      } else if (res.code === "200") {
        let result = res.data;
        // 类型为细胞融合度的时候
        if (result && result.status === "calculating") {
          console.log("計算中");
          setIsCalculating(true);
          if (
            typeHistory === "BoneDensity" ||
            typeHistory === "VertebraeDensity" ||
            typeHistory === "CartilageThickness"
          ) {
            dispatch(
              imgFcSlice.actions.fileName(JSON.parse(result.formImages).name)
            );
            setLeftUrl(ziprar);
          } else {
            dispatch(
              imgFcSlice.actions.fileName(JSON.parse(result.formImages)[0].name)
            );
            dispatch(imgFcSlice.actions.creationDate(result.creationDate));
            setLeftUrl(JSON.parse(result.formImages)[0].url);
            dispatch(
              imgFcSlice.actions.ruleType(JSON.parse(result.formParams))
            );
            setRightUrl(" ");
          }
          // console.log(JSON.parse(result.formImages)[0].name);

          dispatch(imgFcSlice.actions.loading(true));
        } else if (result && result.status === "completed") {
          console.log("2222222");
          if (typeHistory === "CellConfluencyAndCounting") {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            console.log(dispatchResult);
            console.log(result.formParams);
            dispatch(
              imgFcSlice.actions.ruleType(JSON.parse(result.formParams))
            );
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            dispatch(imgFcSlice.actions.creationDate(result.creationDate));
            dispatch(
              imgFcSlice.actions.fileName(JSON.parse(result.formImages)[0].name)
            );
            setConfluency(dispatchResult.Confluency);
            setCounts(dispatchResult.Counts);
            let leftImgUrl = dispatchResult["Inner-Visualization"];
            let rightImgUrl = dispatchResult["Inner-Distribution"];
            console.log(leftImgUrl);
            console.log(leftImgUrl.indexOf("/file/download?"));
            setLeftUrl(
              `${
                BASE_URL +
                leftImgUrl.substr(
                  leftImgUrl.indexOf("/file/download?"),
                  leftImgUrl.length - 1
                )
              }&bearer=${localStorage.getItem("BeadrToken")}`
            );
            setRightUrl(
              `${
                BASE_URL +
                rightImgUrl.substr(
                  leftImgUrl.indexOf("/file/download?"),
                  leftImgUrl.length - 1
                )
              }&bearer=${localStorage.getItem("BeadrToken")}`
            );
            setCalculate(true);
            setIsCalculating(false);
          } else if (typeHistory === "JointInflammation") {
            let { taskType } = JSON.parse(result.formParams);
            if (taskType === "0") {
              // 单选图片
              setShow(false);
              setSwitchDisable(true);
              dispatch(imgFcSlice.actions.loading(true));
              console.log("計算完成");
              hasdellresult = true;
              let dispatchResult = JSON.parse(result.result);
              let dispatchFormParams = JSON.parse(result.formParams);
              console.log(dispatchResult);
              console.log(dispatchFormParams);
              dispatch(imgFcSlice.actions.status(dispatchResult.Status));
              dispatch(
                imgFcSlice.actions.fileName(
                  dispatchFormParams.formImageObjects[0].name
                )
              );
              setInflammation(dispatchResult.InflammationRatio);
              setAreaRatio(dispatchFormParams.regionRatio);
              setDistance(dispatchFormParams.distanceToContour);
              setHOGbuttom(dispatchFormParams.hogLowerBound);
              setHOGtop(dispatchFormParams.hogUpperBound);
              let leftImgUrl = dispatchResult["Inner-Visualization"];
              console.log(leftImgUrl);
              console.log(leftImgUrl.indexOf("/file/download?"));
              setLeftUrl(
                `${
                  BASE_URL +
                  leftImgUrl.substr(
                    leftImgUrl.indexOf("/file/download?"),
                    leftImgUrl.length - 1
                  )
                }&bearer=${localStorage.getItem("BeadrToken")}`
              );
              setCalculate(true);
              setIsCalculating(false);
            } else if (taskType === "1") {
              //  多选图片
              hasdellresult = true;
              setShow(true);
              setSwitchDisable(true);
              let dispatchResult = JSON.parse(result.result);
              let dispatchFormParams = JSON.parse(result.formParams);
              console.log(dispatchResult);
              console.log(dispatchFormParams);
              setFileList(dispatchFormParams.formImageObjects);
              dispatch(imgFcSlice.actions.status(dispatchResult.Status));
              dispatch(
                imgFcSlice.actions.fileName(
                  dispatchFormParams.formImageObjects[0].name
                )
              );
              setInflammation(dispatchResult.InflammationRatio);
              setSpots1(dispatchResult.WeightedInflammationRatio1);
              setSpots2(dispatchResult.WeightedInflammationRatio2);
              setAreaRatio(dispatchFormParams.regionRatio);
              setDistance(dispatchFormParams.distanceToContour);
              setHOGbuttom(dispatchFormParams.hogLowerBound);
              setHOGtop(dispatchFormParams.hogUpperBound);
              setCalculate(true);
              setIsCalculating(false);
            }
          } else if (
            typeHistory === "SkinInjury" ||
            typeHistory === "MassonDetection"
          ) {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            let dispatchFormImg = JSON.parse(result.formImages);
            console.log(dispatchResult);
            console.log(dispatchFormImg);
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            // dispatch(dispatchFormImg[0].name);
            console.log(dispatchFormImg[0]["name"]);
            dispatch(imgFcSlice.actions.fileName(dispatchFormImg[0]["name"]));
            let leftImgUrl = dispatchFormImg[0]["url"];
            console.log(leftImgUrl);
            console.log(leftImgUrl.indexOf("/file/download?"));
            setLeftUrl(
              `${
                BASE_URL +
                leftImgUrl.substr(
                  leftImgUrl.indexOf("/file/download?"),
                  leftImgUrl.length - 1
                )
              }&bearer=${localStorage.getItem("BeadrToken")}`
            );
            if (typeHistory === "SkinInjury") {
              setRedNess(dispatchResult["Redness"]);
              setDamage(dispatchResult["Damage"]);
            }
            if (typeHistory === "MassonDetection") {
              setBluenessProportation(dispatchResult["BluenessIntensity"]);
              setBluenessIntensity(dispatchResult["BluenessProportation"]);
            }
          } else if (
            typeHistory === "TunelDetection" ||
            typeHistory === "IL6Detection"
          ) {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            let dispatchFormImg = JSON.parse(result.formImages);
            console.log(dispatchResult);
            console.log(dispatchFormImg);
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            // 两个图片
            setFirstImg(dispatchFormImg.imageSpGreen.url);
            setSecondImg(dispatchFormImg.imageDAPI.url);
            dispatch(
              imgFcSlice.actions.fileName(dispatchFormImg.imageSpGreen.name)
            );
            setNumPerArea(dispatchResult.NumPerArea);
            setIntensityPerArea(dispatchResult.StrengthPerArea);
            setPerNum(dispatchResult.Num);
            setPreIntensity(dispatchResult.Strength);
            setCalculate(true);
            setIsCalculating(false);
          } else if (typeHistory === "BoneDensity") {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            let dispatchFormImg = JSON.parse(result.formImages);
            console.log(dispatchResult);
            console.log(dispatchFormImg);
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            dispatch(imgFcSlice.actions.fileName(dispatchFormImg.name));
            setCorticalRatio(dispatchResult.CorticalRatio);
            setSpongyratio(dispatchResult.SpongyRatio);
            setLeftUrl(ziprar);
            setCalculate(true);
            setIsCalculating(false);
          } else if (typeHistory === "VertebraeDensity") {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            let dispatchFormImg = JSON.parse(result.formImages);
            console.log(dispatchResult);
            console.log(dispatchFormImg);
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            dispatch(imgFcSlice.actions.fileName(dispatchFormImg.name));
            setSpongyratio(dispatchResult.SpongyRatio);
            setLeftUrl(ziprar);
            setCalculate(true);
            setIsCalculating(false);
          }else if (typeHistory === "CartilageThickness") {
            dispatch(imgFcSlice.actions.loading(true));
            console.log("計算完成");
            hasdellresult = true;
            let dispatchResult = JSON.parse(result.result);
            let dispatchFormImg = JSON.parse(result.formImages);
            console.log('esult.result',result.result);
            console.log(dispatchFormImg);
            dispatch(imgFcSlice.actions.status(dispatchResult.Status));
            dispatch(imgFcSlice.actions.fileName(dispatchFormImg.name));
            console.log('dispatchResult',dispatchResult.Thickness)
            setSpongyratio(dispatchResult.SpongyRatio);
            setThickness(dispatchResult?.Thickness)
            setLeftUrl(ziprar);
            setCalculate(true);
            setIsCalculating(false);
          }
          // setWaitResult(false);
        } else if (result && result.status === "error") {
          dispatch(imgFcSlice.actions.loading(true));
          console.log("計算失敗了");
          hasdellresult = true;
          message.error(`${t("cell.Calculatefailure")}`);
        }
      }
    });
  };
  // const interValGetResult = setInterval(() => getTaskResult(taskId), 10000);
  useEffect(() => {
    console.log("复位了");
    dispatch(imgFcSlice.actions.init(""));
    dispatch(imgFcSlice.actions.loading(true));
    // dispatch(imgFcSlice.actions.setNeedClear(true))
    setWaitResult(false);
    setCalculate(false);
    setIsCalculating(false);
    setRightUrl("");
    setLeftUrl("");
    setRuleTypeNumber("-1");
    setAreaRatio(0.33);
    setDistance(6.0);
    setHOGbuttom(0.25);
    setHOGtop(0.9);
    setInflammation("");
    setSpots1("");
    setSpots2("");
    setSwitchDisable(false);
    setFileList([]);
    setInfoFileList("");
    setRedNess("");
    setDamage("");
    setBluenessIntensity("");
    setBluenessProportation("");
    setFirstImg("");
    setSecondImg("");
    setNumPerArea("");
    setIntensityPerArea("");
    setPerNum("");
    setPreIntensity("");
    setSpongyratio("");
    setCorticalRatio("");
    setCangetResult(false);
    setNoTaskIdFileLists([]);
  }, [dispatch, reset]);

  // 这个是从查看详情进的
  useEffect(() => {
    if (taskId && !waitResult) {
      setShowWhichPra(typeHistory);
      getTaskResult(taskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, waitResult, typeHistory]);
  let i = 0;
  let hasdellresult = false;
  const interval = () => {
    console.log("执行了");
    if (i < 4) {
      i = i + 1;
      if (!hasdellresult) {
        getTaskResult(taskId);
      } else {
        i = 5;
        console.log("执行完毕");
        setWaitResult(false);
      }
    } else {
      setWaitResult(false);
      if (!hasdellresult) {
        message.info(`${t("cell.timeOut")}`);
      } else {
        console.log("得到结果");
      }
      return;
    }
    setTimeout(() => {
      interval();
    }, 10000);
  };
  // 持续计算的得出结果
  useEffect(() => {
    console.log(waitResult);
    if (taskId && !waitResult) {
      console.log(waitResult);
      interval();
    }
    return () => {
      console.log(i);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hasdellresult = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      i = 5;
    };
  }, [taskId, waitResult, i]);
  const handleChange = (info) => {
    let fileList: any = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setNoTaskIdFileLists([...fileList]);
  };
  const props = {
    name: "file",
    maxCount: showMore ? 12 : 1,
    multiple: showMore,
    showUploadList: true,

    action: `${BASE_URL}/file/upload`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("BeadrToken")} `,
    },
    beforeUpload(file) {
      if (
        showWhichPra === "BoneDensity" ||
        showWhichPra === "VertebraeDensity" ||
        showWhichPra === "CartilageThickness"
      ) {
        const checkType = new Promise<void>((resolve) => {
          console.log(file.name.substr(file.name.lastIndexOf(".") + 1));
          if (
            file.name.substr(file.name.lastIndexOf(".") + 1) === "zip"
            //  ||
            // file.name.substr(file.name.lastIndexOf(".") + 1) === "rar"
          ) {
            resolve();
          } else {
            message.error("请上传正确格式的文件");
            return false;
          }
        });
        return checkType;
      }
    },
    onChange(info) {
      handleChange(info);
      const { status } = info.file;
      if (status !== "uploading") {
        if (!showMore) {
          if (
            showWhichPra === "BoneDensity" ||
            showWhichPra === "VertebraeDensity" ||
            showWhichPra === "CartilageThickness"
          ) {
            if (info.fileList.every((x) => x.status === "done")) {
              setCangetResult(true);
            }
            dispatch(imgFcSlice.actions.fileName(info.file.name));
            dispatch(
              imgFcSlice.actions.url(
                info.fileList[0].response.operation.data.content
              )
            );
            console.log(info.fileList[0].response.operation.data.content);

            setLeftUrl(info.fileList[0].response.operation.data.content);
          } else {
            console.log(info.file, info.fileList);
            console.log(info.file.name);
            if (info.fileList.every((x) => x.status === "done")) {
              setCangetResult(true);
            }
            dispatch(imgFcSlice.actions.fileName(info.file.name));
            dispatch(
              imgFcSlice.actions.url(
                info.fileList[0].response.operation.data.content
              )
            );
            let leftImgUrl = info.fileList[0].response.operation.data.content;
            setLeftUrl(`${leftImgUrl}`);
          }
        } else {
          console.log(info.file, info.fileList);
          console.log("执行的这个");
          if (info.fileList.every((x) => x.status === "done")) {
            setCangetResult(true);
          }
          console.log(info.file.name);
          dispatch(imgFcSlice.actions.fileName(info.file.name));
          setInfoFileList(info.fileList);
        }
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.log(info.fileList[0].response);
        if (info.fileList[0].response.code === "401") {
          message.error(`${info.fileList[0].response.operation.data.content}`);
          setTimeout(() => {
            localStorage.clear();
            dispatch(userSlice.actions.token(""));
            history.push(`${process.env.PUBLIC_URL}/routepages/login`);
          }, 2000);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const leftProps = {
    name: "file",
    maxCount: showMore ? 12 : 1,
    multiple: showMore,
    showUploadList: true,
    action: `${BASE_URL}/file/upload`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("BeadrToken")} `,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        if (!showMore) {
          console.log(info.file, info.fileList);

          console.log(info.file.name);
          dispatch(imgFcSlice.actions.fileName(info.file.name));
          dispatch(
            imgFcSlice.actions.url(
              info.fileList[0].response.operation.data.content
            )
          );
          let leftImgUrl = info.fileList[0].response.operation.data.content;
          setFirstImg(`${leftImgUrl}`);
        } else {
          console.log(info.file, info.fileList);
          console.log(info.file.name);
          dispatch(imgFcSlice.actions.fileName(info.file.name));
          setInfoFileList(info.fileList);
        }
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.log(info.fileList[0].response);
        if (info.fileList[0].response.code === "401") {
          message.error(`${info.fileList[0].response.operation.data.content}`);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const rightProps = {
    name: "file",
    maxCount: showMore ? 12 : 1,
    multiple: showMore,
    showUploadList: true,
    action: `${BASE_URL}/file/upload`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("BeadrToken")} `,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        if (!showMore) {
          console.log(info.file, info.fileList);
          console.log(info.file.name);
          dispatch(imgFcSlice.actions.fileName(info.file.name));
          dispatch(
            imgFcSlice.actions.url(
              info.fileList[0].response.operation.data.content
            )
          );
          let leftImgUrl = info.fileList[0].response.operation.data.content;
          setSecondImg(`${leftImgUrl}`);
        } else {
          console.log(info.file, info.fileList);
          console.log(info.file.name);
          dispatch(imgFcSlice.actions.fileName(info.file.name));
          setInfoFileList(info.fileList);
        }
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.log(info.fileList[0].response);
        if (info.fileList[0].response.code === "401") {
          message.error(`${info.fileList[0].response.operation.data.content}`);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  //   const dispatch = useDispatch();
  const onChangeRadiu = (e) => {
    console.log("radio checked", e.target.value);
    setradiuValue(e.target.value);
  };
  const handleChangeSelect = (value) => {
    console.log(`selected ${value}`);
    if (value === "无比例尺") {
      setRuleTypeNumber("0");
    }
    if (value === "透明比例尺") {
      setRuleTypeNumber("1");
    }
    if (value === "白底比例尺") {
      setRuleTypeNumber("2");
    }
    if (value === "请选择") {
      setRuleTypeNumber("-1");
    }
    dispatch(imgFcSlice.actions.ruleType(value));
  };

  const getResult = () => {
    console.log(fileName, enTitle, showWhichPra);
    if (enTitle === "Cell Confluency and Counting") {
      if (!fileName || !ruleType || !url) {
        console.log(fileName, ruleType, url);
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          start({
            formParams: JSON.stringify({ ScaleType: ruleTypeNumber }),
            type: "CellConfluencyAndCounting",
            formImageObjects: [
              {
                name: fileName,
                url: leftUrl,
              },
            ],
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/Confluency") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "JointInflammation") {
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        if (buttom > top) {
          message.error(`HOG上界值需大于HOG下界值`);
        } else {
          setLoading(true);
          if (!showMore) {
            dispatch(
              jointInflammationTask({
                // type: "JointInflammation",
                distanceToContour: distance,
                hogLowerBound: HOGbuttom,
                hogUpperBound: HOGtop,
                regionRatio: arearatio,
                taskType: showMore ? "1" : "0",
                formImageObjects: [
                  {
                    name: fileName,
                    url: leftUrl,
                  },
                ],
              })
            );
          } else {
            console.log(infoFilelist);
            let formImageObjectsArr: any = [];
            infoFilelist.forEach((x) => {
              formImageObjectsArr.push({
                name: x.name,
                url: x.response.operation.data.content,
              });
            });
            console.log(formImageObjectsArr);
            dispatch(
              jointInflammationTask({
                // type: "JointInflammation",
                distanceToContour: distance,
                hogLowerBound: HOGbuttom,
                hogUpperBound: HOGtop,
                regionRatio: arearatio,
                taskType: "1",
                formImageObjects: formImageObjectsArr,
              })
            );
          }
          dispatch(imgFcSlice.actions.setNeedClear(false));

          setTimeout(() => {
            console.log(window.location.href);
            if (
              window.location.href.indexOf("/home/JointInflammation") !== -1
            ) {
              history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
            } else if (
              window.location.href.indexOf("/home/Confluency") !== -1
            ) {
              history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
            }
          }, 3000);
        }
      }
    } else if (showWhichPra === "SkinInjury") {
      console.log("fileName", fileName);
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          skinInjuryTask({
            formImageObjects: [
              {
                // leftUrl
                name: fileName,
                url: leftUrl,
              },
            ],
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/SkinInjury") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "MassonDetection") {
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          massonDetectionTask({
            formImageObjects: [
              {
                name: fileName,
                url: leftUrl,
              },
            ],
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/MassonDetection") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "IL6Detection") {
      if (!firstImg || !secondImg) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          il6DetectionTask({
            imageDAPI: {
              name: fileName,
              url: firstImg,
            },
            imageSpGreen: {
              name: fileName,
              url: secondImg,
            },
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/IL6Detection") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "TunelDetection") {
      if (!firstImg || !secondImg) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          tunelDetectionTask({
            imageDAPI: {
              name: "fileName",
              url: firstImg,
            },
            imageSpGreen: {
              name: "fileName",
              url: secondImg,
            },
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/TunelDetection") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "BoneDensity") {
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          boneDensityTask({
            url: leftUrl,
            name: fileName,
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/BoneDensity") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    } else if (showWhichPra === "VertebraeDensity") {
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
          vertebraeDensityTask({
            url: leftUrl,
            name: fileName,
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/VertebraeDensity") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    }else if (showWhichPra === "CartilageThickness") {
      if (!fileName) {
        message.error(`${t("cell.getResultCk")}`);
      } else {
        setLoading(true);
        dispatch(
            cartilageThicknessTask({
            url: leftUrl,
            name: fileName,
          })
        );
        dispatch(imgFcSlice.actions.setNeedClear(false));
        setTimeout(() => {
          console.log(window.location.href);
          if (window.location.href.indexOf("/home/CartilageThickness") !== -1) {
            history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
          }
        }, 3000);
      }
    }
  };
  useEffect(() => {
    if (gotoHistory) {
      setTimeout(() => {
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
      }, 2000);

      return () => {
        dispatch(imgFcSlice.actions.setGotoHistory(false));
      };
    }
  }, [dispatch, gotoHistory, history]);
  // 填完数据点击立即计算跳到这个页面
  // useEffect(() => {
  //   console.log("loading", loading);
  //   if (!loading) {
  //     setWaitResult(true);
  //     history.push(
  //       `${process.env.PUBLIC_URL}/home/${urlType}?taskId=${taskIdRedux}&type=${type}`
  //     );
  //   }
  // }, [history, loading, taskIdRedux, type, urlType]);
  // 思路： 用urlType来判断显示哪个参数
  // const url =
  //   "https://ai-api.wolwobiotech.com/file/download?objectName=1639707392243lgMJ.tif";
  // const tiff = new Tiff({ buffer: dsadsa });
  // const imgsssss = tiff.toDataURL();
  const IsTiff = (url: string) => {
    const type = url.substr(url.lastIndexOf(".") + 1);
    return type;
  };
  return (
    <div className={styles.page}>
      {/* 上部功能说明 */}
      <Title
        style={{
          letterSpacing: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
        level={2}
      >
        <span> {title}</span>
        <div className={styles.addNew}>
          <Button
            style={{
              background: "#006fff",
              fontFamily: "PingFangSC-Regular, PingFang SC",
              padding: "4px 16px",
            }}
            type="primary"
            onClick={() => {
              console.log("2");
              if (taskId) {
                dispatch(imgFcSlice.actions.setNeedClear(true));
                if (showWhichPra === "CellConfluencyAndCounting") {
                  history.push(`${process.env.PUBLIC_URL}/home/Confluency`);
                  clean();
                }
                if (showWhichPra === "JointInflammation") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/JointInflammation`
                  );
                  clean();
                }
                if (showWhichPra === "SkinInjury") {
                  history.push(`${process.env.PUBLIC_URL}/home/SkinInjury`);
                  clean();
                }
                if (showWhichPra === "IL6Detection") {
                  history.push(`${process.env.PUBLIC_URL}/home/IL6Detection`);
                  clean();
                }
                if (showWhichPra === "TunelDetection") {
                  history.push(`${process.env.PUBLIC_URL}/home/TunelDetection`);
                  clean();
                }
                if (showWhichPra === "MassonDetection") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/MassonDetection`
                  );
                  clean();
                }
                if (showWhichPra === "BoneDensity") {
                  history.push(`${process.env.PUBLIC_URL}/home/BoneDensity`);
                  clean();
                }
                if (showWhichPra === "VertebraeDensity") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/VertebraeDensity`
                  );
                  clean();
                }
                if (showWhichPra === "CartilageThickness") {
                  history.push(
                    `${process.env.PUBLIC_URL}/home/CartilageThickness`
                  );
                  clean();
                }
              } else {
                clean();
              }
            }}
          >
            + {t("history.add")}
          </Button>
        </div>
      </Title>
      <Title
        style={{ letterSpacing: 1, marginTop: -10, color: "#006FFF" }}
        level={5}
      >
        {enTitle}
      </Title>
      <Title style={{ letterSpacing: 1, fontSize: 18 }}>{des}</Title>
      <Title
        style={{
          letterSpacing: 1,
          fontSize: 14,
          marginTop: 42,
          color: "rgb(0,0,0,0.65)",
          marginBottom: 25,
        }}
      >
        {t("function.smallDes")}
      </Title>
      {/* 内容部分 */}
      <Modal
        style={{ textAlign: "center" }}
        centered={true}
        closable={false}
        visible={example}
        okText={t("cell.download")}
        cancelText={t("cell.cancle")}
        onOk={() => {
          setExample(false);
        }}
        onCancel={() => {
          setExample(false);
        }}
        footer={[
          <div style={{ textAlign: "center" }}>
            {" "}
            <Button
              onClick={() => {
                setExample(false);
              }}
            >
              {t("cell.cancle")}
            </Button>
          </div>,
        ]}
      >
        <div>
          <img style={{ height: "100%", width: "100%" }} src={imgTest} alt="" />
        </div>
      </Modal>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.contentLeftHeader}>
            <div className={styles.contentLeftHeaderTitle}>
              {showWhichPra === "CellConfluencyAndCounting"
                ? t("cell.upload")
                : t("cell.uploadNormal")}
              <span
                onClick={() => {
                  setExample(true);
                }}
              >
                {t("cell.example")}
              </span>
            </div>{" "}
            {switchShow ? (
              <div className={styles.contentLeftHeaderAction}>
                <span style={{ fontSize: 18 }}>{t("cell.singleLoad")}</span>
                {showMore ? (
                  <Switch
                    disabled={switchDisable}
                    style={{
                      backgroundColor: "#006fff",
                      marginBottom: 2,
                      marginLeft: 15,
                    }}
                    checked={true}
                    // checkedChildren={t("cell.open")}
                    // unCheckedChildren={t("cell.close")}
                    defaultChecked={false}
                    onClick={() => {
                      setShow(!showMore);
                      dispatch(imgFcSlice.actions.fileName(""));
                      setCangetResult(false);
                      setLeftUrl("");
                    }}
                  />
                ) : (
                  <Switch
                    disabled={switchDisable}
                    style={{
                      backgroundColor: "#006fff",
                      marginBottom: 2,
                      marginLeft: 15,
                    }}
                    checked={false}
                    // checkedChildren={t("cell.open")}
                    // unCheckedChildren={t("cell.close")}
                    defaultChecked={false}
                    onClick={() => {
                      setShow(!showMore);
                      setCangetResult(false);
                      dispatch(imgFcSlice.actions.fileName(""));
                      setLeftUrl("");
                    }}
                  />
                )}
                <span style={{ marginLeft: 5, fontSize: 18 }}>
                  {" "}
                  {t("cell.moreupload")}
                </span>
              </div>
            ) : null}
          </div>
          {/* 根据是否批量选择 来决定左边显示什么 */}
          <Modal
            style={{ textAlign: "center" }}
            centered={true}
            closable={false}
            visible={isModalVisible}
            okText={t("cell.download")}
            cancelText={t("cell.cancle")}
            onOk={() => {
              setIsModalVisible(false);
              window.open(moduleurl);
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            footer={[
              <div style={{ textAlign: "center" }}>
                {" "}
                <Button
                  onClick={() => {
                    setIsModalVisible(false);
                  }}
                >
                  {t("cell.cancle")}
                </Button>
                <Button
                  style={{ backgroundColor: "#006fff" }}
                  type="primary"
                  onClick={() => {
                    setIsModalVisible(false);
                    window.open(moduleurl);
                  }}
                >
                  {t("cell.download")}
                </Button>
              </div>,
            ]}
          >
            <div>
              <img
                style={{ height: "100%", width: "100%" }}
                src={moduleurl}
                alt=""
              />
            </div>
          </Modal>
          <div className={styles.leftContent}>
            {!taskId && leftUrl ? (
              <div className={styles.imgReUpload}>
                <span onClick={clean}>{t("cell.reupload")}</span>
              </div>
            ) : null}
            {showWhichPra === "IL6Detection" ||
            showWhichPra === "TunelDetection" ? (
              <>
                <div className={styles.twoImgsBox}>
                  {" "}
                  <>
                    {" "}
                    <div
                      style={{
                        height: "472px",
                        width: "100%",
                      }}
                    >
                      <div className={styles.twoImgsBox}>
                        {" "}
                        {firstImg ? (
                          <div style={{ width: "340px" }}>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={firstImg}
                              alt=""
                            />
                          </div>
                        ) : (
                          <Dragger
                            style={{ height: "100%", width: 340 }}
                            {...leftProps}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ height: "100%" }} />
                            </p>
                            <p className="ant-upload-text">
                              {t("cell.clickSpGreen")}
                            </p>
                          </Dragger>
                        )}
                        {secondImg ? (
                          <div style={{ width: "340px" }}>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={secondImg}
                              alt=""
                            />
                          </div>
                        ) : (
                          <Dragger
                            style={{ height: "100%", width: 340 }}
                            {...rightProps}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ height: "100%" }} />
                            </p>
                            <p className="ant-upload-text">
                              {t("cell.clickDAPI")}
                            </p>
                          </Dragger>
                        )}
                      </div>
                    </div>
                  </>{" "}
                </div>
              </>
            ) : (
              <>
                {showMore ? (
                  <div className={styles.showmoreContent}>
                    {" "}
                    {taskId && filesLists.length > 0 ? (
                      <div className={styles.draggerPic}>
                        {filesLists.map((x, index) => {
                          return (
                            <div
                              className={styles.filesListItem}
                              key={index}
                              onClick={() => {
                                setIsModalVisible(!isModalVisible);
                                setModuleUrl(x.url);
                                console.log("2");
                              }}
                            >
                              {" "}
                              <IconFont
                                type="iconfujian"
                                style={{
                                  marginRight: "10px",
                                  fontSize: 14,
                                  color: "#838A9D",
                                }}
                              />
                              {x.name}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Dragger
                        fileList={noTaskIdFileLists}
                        className={styles.dragger}
                        {...props}
                      >
                        <p className={styles.PlusOutlined}>
                          <PlusOutlined
                            style={{
                              color: "#666",
                              fontSize: 20,
                              marginBottom: 0,
                            }}
                          />{" "}
                          {t("cell.up")}
                        </p>
                      </Dragger>
                    )}
                  </div>
                ) : (
                  <>
                    {" "}
                    {leftUrl ? (
                      <div className={styles.leftUrlBox}>
                        {" "}
                        {IsTiff(leftUrl) === "tif" ? (
                          <div>
                            <img
                              style={{ width: "250px", height: "150px" }}
                              src={tiffNo}
                              alt=""
                            />
                            <div style={{ fontSize: 14, color: "#000000" }}>
                              上传成功，Tiff格式图片暂不支持预览
                            </div>
                          </div>
                        ) : (
                          <>
                            {showWhichPra === "BoneDensity" ||
                            showWhichPra === "VertebraeDensity" ||
                            showWhichPra === "CartilageThickness" ? (
                              <div>
                                <img
                                  style={{ width: "250px", height: "150px" }}
                                  src={ziprar}
                                  alt=""
                                />
                                <div style={{ fontSize: 14, color: "#000000" }}>
                                  {t("cell.uploadazipsuccess")}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <img src={leftUrl} alt="" />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div style={{ height: "472px" }}>
                        <Dragger
                          accept={
                            showWhichPra === "BoneDensity" ||
                            showWhichPra === "VertebraeDensity" ||
                            showWhichPra === "CartilageThickness"
                              ? ".zip"
                              : "image/*,.pdf"
                          }
                          {...props}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined style={{ height: "100%" }} />
                          </p>
                          {showWhichPra === "BoneDensity" ||
                          showWhichPra === "VertebraeDensity" ||
                          showWhichPra === "CartilageThickness" ? (
                            <>
                              {" "}
                              <p className="ant-upload-text">
                                {t("cell.clickipraa")}
                              </p>
                              <p className="ant-upload-text">
                                {t("cell.rarzip")}
                              </p>
                            </>
                          ) : (
                            <p className="ant-upload-text">
                              {t("cell.clickUp")}
                            </p>
                          )}
                        </Dragger>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {isCalculating && !showMore ? <Mask /> : null}
          </div>
        </div>
        {/* 融合度参数设置 */}
        {/* 根据 urlType 显示什么参数*/}
        {/* 只有单张才显示蒙板  */}
        <div className={styles.contentRight}>
          {isCalculating && !showMore ? <Mask /> : null}
          <div className={styles.contentRightHeader}>{t("cell.dataSet")}</div>
          <div className={styles.rightContent}>
            <div className={styles.rightContentTop}>
              {showWhichPra !== "JointInflammation" ? (
                <div>
                  {" "}
                  <span className={styles.name}>{t("cell.name")}</span>
                  <span className={styles.value}>{fileName}</span>{" "}
                </div>
              ) : null}

              {/* taskid存在代表上传完所有参数  点击了开始计算 */}
              {/* 目前  细胞融合度，，需要时间 */}
              {taskId ? (
                <>
                  {/* 单张照片的结果 */}
                  {showWhichPra === "CellConfluencyAndCounting" ? (
                    <div className={styles.date}>
                      <span className={styles.name}>{t("cell.date")}</span>{" "}
                      <span className={styles.value}>
                        {formatTime(creationDate)}
                      </span>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {/* 点击了开始计算 ? */}
                  {/* 此处的时间为 点击完计算但是没有返回结果  而用当前时间显示 */}
                  {hasCalculate ? (
                    <>
                      {showWhichPra === "CellConfluencyAndCounting" ? (
                        <div className={styles.date}>
                          <span className={styles.name}>{t("cell.date")}</span>{" "}
                          <span className={styles.value}>
                            {formatTime(dayjs().valueOf())}
                          </span>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
              {/* 有结果了  展示处需要的值 */}
              {taskId ? (
                <>
                  {showWhichPra === "CellConfluencyAndCounting" ? (
                    <div>
                      <span className={styles.name}> {t("cell.ruler")}</span>{" "}
                      <span className={styles.value}>
                        {ruleType.ScaleType === "0"
                          ? `${t("cell.ruler1")}`
                          : null}
                        {ruleType.ScaleType === "1"
                          ? `${t("cell.ruler2")}`
                          : null}
                        {ruleType.ScaleType === "2"
                          ? `${t("cell.ruler3")}`
                          : null}
                      </span>
                    </div>
                  ) : null}
                  {showWhichPra === "JointInflammation" ? (
                    <>
                      <div className={styles.boneJointsTop}>
                        <div className={styles.boneJointsLabel}>
                          {showMore ? (
                            <>
                              {" "}
                              {bonesList.splice(1, 3).map((x) => {
                                return (
                                  <div className={styles.boneName}>{x}</div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {" "}
                              {bonesList.map((x) => {
                                return (
                                  <div className={styles.boneName}>{x}</div>
                                );
                              })}
                            </>
                          )}
                        </div>
                        <div className={styles.boneJointsValue}>
                          {showMore ? null : (
                            <span className={styles.boneJointsvalueName}>
                              {fileName}
                            </span>
                          )}{" "}
                          <div className={styles.boneJointsvalueName}>
                            {arearatio}
                          </div>{" "}
                          <div className={styles.boneJointsvalueName}>
                            {distance}
                          </div>{" "}
                          <div className={styles.boneJointsvalueName}>
                            {HOGbuttom} ~ {HOGtop}
                          </div>{" "}
                          {/* <div className={styles.boneJointsvalueName}>
                            {HOGtop}
                          </div> */}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* 在这写的得到的结果 top */}
                </>
              ) : (
                <>
                  {/* 没返回结果之前&&点击了计算之后  显示的比例尺 */}
                  {hasCalculate ? (
                    <>
                      {showWhichPra === "CellConfluencyAndCounting" ? (
                        <div>
                          <span className={styles.name}>
                            {" "}
                            {t("cell.ruler")}
                          </span>{" "}
                          <span className={styles.value}>
                            {ruleType.ScaleType === "0"
                              ? `${t("cell.ruler1")}`
                              : null}
                            {ruleType.ScaleType === "1"
                              ? `${t("cell.ruler2")}`
                              : null}
                            {ruleType.ScaleType === "2"
                              ? `${t("cell.ruler3")}`
                              : null}
                          </span>
                        </div>
                      ) : null}
                      {/* 点击了 */}
                      {showWhichPra === "JointInflammation" ? (
                        <>
                          {" "}
                          <div className={styles.boneJointsTop}>
                            <div className={styles.boneJointsLabel}>
                              {showMore ? (
                                <>
                                  {" "}
                                  {bonesList.splice(1, 3).map((x) => {
                                    return (
                                      <div className={styles.boneName}>{x}</div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {bonesList.map((x) => {
                                    return (
                                      <div className={styles.boneName}>{x}</div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                            <div className={styles.boneJointsValue}>
                              {showMore ? null : (
                                <span className={styles.boneJointsvalueName}>
                                  {fileName}
                                </span>
                              )}
                              <div className={styles.boneJointsvalueName}>
                                {arearatio}
                              </div>{" "}
                              <div className={styles.boneJointsvalueName}>
                                {distance}
                              </div>{" "}
                              <div className={styles.boneJointsvalueName}>
                                {HOGbuttom} ~ {HOGtop}
                              </div>{" "}
                              {/* <div className={styles.boneJointsvalueName}>
                                {HOGtop}
                              </div> */}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* 进页面看到的效果 */}
                      {/* 区域比例 */}
                      {showWhichPra === "JointInflammation" ? (
                        <>
                          <div className={styles.boneJointsTop}>
                            <div className={styles.boneJointsLabel}>
                              {showMore ? (
                                <>
                                  {" "}
                                  {bonesList.splice(1, 3).map((x) => {
                                    return (
                                      <div className={styles.boneName}>{x}</div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {bonesList.map((x) => {
                                    return (
                                      <div className={styles.boneName}>{x}</div>
                                    );
                                  })}
                                </>
                              )}
                            </div>

                            <div className={styles.boneJointsValue}>
                              {showMore ? null : (
                                <span className={styles.boneJointsvalueName}>
                                  {fileName}
                                </span>
                              )}
                              <div className={styles.boneJointsInputBox}>
                                <Slider
                                  marks={marksarearatio}
                                  step={0.01}
                                  min={0}
                                  max={1}
                                  defaultValue={arearatio}
                                  onChange={setAreaRatio}
                                />
                              </div>{" "}
                              <div className={styles.boneJointsInputBox}>
                                <Slider
                                  marks={marksdistance}
                                  step={0.01}
                                  min={0}
                                  max={10}
                                  defaultValue={distance}
                                  onChange={setDistance}
                                />
                              </div>{" "}
                              <div className={styles.boneJointsInputBox}>
                                <Slider
                                  marks={marksHOGbuttomHOGtop}
                                  step={0.01}
                                  min={0}
                                  max={1}
                                  defaultValue={[HOGbuttom, HOGtop]}
                                  // defaultValue={distance}
                                  // onChange={setDistance}
                                  range={true}
                                  onChange={(e) => {
                                    console.log(e);
                                    setHOGtop(e[1]);
                                    setHOGbuttom(e[0]);
                                  }}
                                  onAfterChange={(e) => {
                                    console.log(e);
                                  }}
                                />
                              </div>
                              <div className={styles.boneJointsInputBox}></div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {showWhichPra === "CellConfluencyAndCounting" ? (
                        <div className={styles.rulesType}>
                          <span className={styles.name}>
                            {" "}
                            {t("cell.rulerType")}
                          </span>{" "}
                          <span>
                            <Select
                              style={{ width: 200 }}
                              onChange={handleChangeSelect}
                              value={`${
                                ruleTypeNumber === "0"
                                  ? `${t("cell.ruler1")}`
                                  : ""
                              }${
                                ruleTypeNumber === "1"
                                  ? `${t("cell.ruler2")}`
                                  : ""
                              }${
                                ruleTypeNumber === "2"
                                  ? `${t("cell.ruler3")}`
                                  : ""
                              }${
                                ruleTypeNumber === "-1"
                                  ? `${t("cell.pleSe")}`
                                  : ""
                              }`}
                            >
                              <Option value="透明比例尺">
                                {t("cell.ruler2")}
                              </Option>
                              <Option value="白底比例尺">
                                {t("cell.ruler3")}
                              </Option>

                              <Option value="无比例尺">
                                {t("cell.ruler1")}
                              </Option>
                            </Select>
                          </span>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
            {/* 有结果了  右边下部分的结果，因为右边下部分结果不尽相同，因此下次如下 */}
            {/* !去掉{" "} */}
            {taskId ? (
              <div className={styles.rightContentBottom}>
                {showWhichPra === "CellConfluencyAndCounting" ? (
                  <>
                    <div className={styles.rightContentBottomTop}>
                      <div className={styles.merge}>
                        <span className={styles.name}>
                          {t("cell.Confluency")}
                        </span>
                        <span className={styles.valueNumber}>{Confluency}</span>
                      </div>
                      <div className={styles.skills}>
                        <span className={styles.name}>{t("cell.Counts")}</span>
                        <span className={styles.valueNumber}>{Counts}</span>
                      </div>
                    </div>
                    <div className={styles.rightContentBottomBottom}>
                      <div className={styles.rightContentBottomBottomImg}>
                        <Image src={rightUrl} alt="" />
                      </div>
                    </div>
                  </>
                ) : null}
                {showWhichPra === "TunelDetection" ||
                showWhichPra === "IL6Detection" ? (
                  <>
                    <div className={styles.rightContentBottomTopcolumn}>
                      <div>
                        <span className={styles.name}>
                          {t("cell.numPerArea")}
                        </span>
                        <span className={styles.valueNumberCloumn}>
                          {numPerArea}
                        </span>
                      </div>
                      <div>
                        <span className={styles.name}>
                          {t("cell.intensityPerArea")}
                        </span>{" "}
                        <span className={styles.valueNumberCloumn}>
                          {intensityPerArea}
                        </span>
                      </div>
                      <div>
                        <span className={styles.name}>{t("cell.perNum")}</span>{" "}
                        <span className={styles.valueNumberCloumn}>
                          {perNum}
                        </span>
                      </div>
                      <div>
                        <span className={styles.name}>
                          {t("cell.preIntensity")}
                        </span>{" "}
                        <span className={styles.valueNumberCloumn}>
                          {preIntensity}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
                {showWhichPra === "SkinInjury" ? (
                  <>
                    <div className={styles.rightContentBottomTop}>
                      <div className={styles.merge}>
                        <span className={styles.name}>{t("cell.redness")}</span>
                        <span className={styles.valueNumber}>{redness}</span>
                      </div>
                      <div className={styles.skills}>
                        <span className={styles.name}>{t("cell.damage")}</span>
                        <span className={styles.valueNumber}>{damage}</span>
                      </div>
                    </div>
                  </>
                ) : null}
                {showWhichPra === "MassonDetection" ? (
                  <>
                    <div className={styles.rightContentBottomTopcolumn}>
                      <div>
                        <span className={styles.name}>
                          {t("cell.bluenessProportation")}
                        </span>
                        <span className={styles.valueNumberCloumn}>
                          {bluenessProportation}
                        </span>
                      </div>
                      <div>
                        <span className={styles.name}>
                          {t("cell.bluenessIntensity")}
                        </span>{" "}
                        <span className={styles.valueNumberCloumn}>
                          {bluenessIntensity}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
                {showWhichPra === "JointInflammation" ? (
                  <div className={styles.rightboneJointsContentBottomTop}>
                    <div className={styles.merge}>
                      <span className={styles.name}>
                        {t("history.yanzhengchengdu")}
                      </span>
                      <span className={styles.valueNumberBone}>
                        {inflammation}
                      </span>
                    </div>

                    {showMore ? (
                      <>
                        {" "}
                        <div className={styles.merge}>
                          <span className={styles.name}>
                            {t("history.spots1")}
                          </span>
                          <span className={styles.valueNumberBone}>
                            {spots1}
                          </span>
                        </div>
                        <div className={styles.merge}>
                          <span className={styles.name}>
                            {t("history.spots2")}
                          </span>
                          <span className={styles.valueNumberBone}>
                            {spots2}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : null}
                {showWhichPra === "BoneDensity" ? (
                  <div className={styles.rightboneJointsContentBottomTop}>
                    <div className={styles.merge}>
                      <span className={styles.name}>
                        {t("cell.Spongyratio")}
                      </span>
                      <span className={styles.valueNumberBone}>
                        {spongyratiovalue}
                      </span>
                    </div>{" "}
                    <div className={styles.merge}>
                      <span className={styles.name}>
                        {t("cell.CorticalRatio")}
                      </span>
                      <span className={styles.valueNumberBone}>
                        {corticalRatiovalue}
                      </span>
                    </div>
                  </div>
                ) : null}
                {showWhichPra === "VertebraeDensity" ? (
                  <div className={styles.rightboneJointsContentBottomTop}>
                    <div className={styles.merge}>
                      <span className={styles.name}>
                        {t("cell.Spongyratio")}
                      </span>
                      <span className={styles.valueNumberBone}>
                        {spongyratiovalue}
                      </span>
                    </div>{" "}
                  </div>
                ) : null}
                {showWhichPra === "CartilageThickness" ? (
                  <div className={styles.CartilageThickness}>
                  <span className={styles.name}>
                      {t("cell.CartilageThicknessName")}： <span className={styles.space}> {t("cell.CartilageThicknessData")}：</span>
                  </span>
                    {thickness?.map((item) => {
                        return (
                            <>
                              <div className={styles.merge}>
                              <span className={styles.thicknessNamePhoto}>
                                {item.PhotoName}:
                              </span>
                              <span className={styles.thicknessName}>
                                {item.Thickness}{t("cell.unit")}
                              </span>
                              </div>
                            </>
                        )
                      })}
                    {" "}
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {/* 无结果  没点击开始结算   控制开始计算按钮显示 */}
                {!hasCalculate ? (
                  <div className={styles.buttonArea}>
                    {canGetResult ? (
                      <Button
                        className={styles.buttonColor}
                        style={{
                          backgroundColor: "#006fff ",
                          color: "#fff",
                          marginBottom: "32px",
                          fontSize: "16px",
                          padding: " 0 28px ",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getResult();
                        }}
                        type="primary"
                        loading={loadingValue}
                      >
                        {t("cell.startget")}
                      </Button>
                    ) : (
                      <button className={styles.buttonColorNo}>
                        {t("cell.startget")}
                      </button>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      {/* 提交反馈 */}
      {taskId && status === "Completed" ? (
        <div className={styles.response}>
          <div className={styles.responseName}> {t("history.report")}</div>
          <div className={styles.responseContent}>
            <div className={styles.responseContentName}>
              <div className={styles.name}>{t("history.exact")}</div>
              <div style={{ marginTop: 30 }} className={styles.name}>
                {t("history.reportCon")}
              </div>
            </div>
            <div className={styles.responseContentValue}>
              <div>
                {" "}
                <Radio.Group onChange={onChangeRadiu} value={radiuValue}>
                  <Radio value={1}> {t("history.exact1")}</Radio>
                  <Radio value={2}> {t("history.exact2")}</Radio>
                  <Radio value={3}> {t("history.exact3")}</Radio>
                </Radio.Group>
              </div>
              <div className={styles.TextArea}>
                <TextArea
                  onChange={(e) => {
                    console.log(e.target.value);
                    dispatch(imgFcSlice.actions.content(e.target.value));
                  }}
                  placeholder={t("history.reportDes")}
                  rows={3}
                  maxLength={300}
                  allowClear={true}
                  value={content}
                />
              </div>
            </div>
          </div>
          <div className={styles.responseButton}>
            <div className={styles.cancle}>{t("userCenter.cancle")}</div>
            <div
              onClick={() => {
                let accuracyTypes = "";
                if (radiuValue === 1) {
                  accuracyTypes = "accurate";
                } else if (radiuValue === 2) {
                  accuracyTypes = "uncertain";
                } else if (radiuValue === 3) {
                  accuracyTypes = "inaccurate";
                }
                dispatch(
                  feedback({
                    accuracyType: accuracyTypes,
                    content: content,
                    taskId: taskId,
                  })
                );
                dispatch(imgFcSlice.actions.afterfeedback(""));
                setradiuValue(1);
              }}
              className={styles.submit}
            >
              {t("cell.submit")}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImgFunction;
