import { FC, useEffect } from "react";
import styles from "./vertebraeDensity.module.css";
import yaozhuitest from "../../../../public/assest/yaozhuitest.jpg";
import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const VertebraeDensity: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("homePage.fs8")}
        enTitle={"VertebraeDensity"}
        des={t("homePage.fs8renwu")}
        urlType={"VertebraeDensity"}
        switchShow={false}
        imgTest={yaozhuitest}
        showWhichType={"VertebraeDensity"}
      />
    </div>
  );
};

export default VertebraeDensity;
