import { useEffect } from "react";
import { useHistory } from "react-router-dom";
const NoFound = () => {
  const history = useHistory();
  useEffect(() => {
    history.push(`${process.env.PUBLIC_URL}/routepages/Welcome`);
  }, [history]);
  return <>2</>;
};
export default NoFound;
