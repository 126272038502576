import { FC } from "react";

const Page: FC = () => {
  return (
    <div>
      {" "}
      wwwwwwwwwwww
      <></>{" "}
    </div>
  );
};

export default Page;
