import { FC } from "react";
import styles from "./il6.module.css";
import celltest from "../../../../public/assest/celltest.jpg";
import ImgFunction from "../../../component/imgFunction/compoment/imgFunction";
import { useTranslation } from "react-i18next";
const IL6: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.page}>
      <ImgFunction
        title={t("homePage.fs4")}
        enTitle={"IL6 Detection"}
        des={t("homePage.fs4detail")}
        urlType={"IL6Detection"}
        switchShow={false}
        showWhichType={"IL6Detection"}
        imgTest={celltest}
      />
    </div>
  );
};

export default IL6;
