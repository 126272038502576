import styles from "./App.module.css";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Register from "./pages/regeister";
import Login from "./pages/login";
import ForgetPsd from "./pages/forgetPsd";
import RoutePages from "./pages/routePages";
import NoFound from "./pages/errorPage";
import { Redirect } from "react-router-dom";
import CellFusion from "./pages/cellFusion/compoment/cellFusion";
import BoneJoints from "./pages/boneJoints/compoment/boneJoints";
import Skin from "./pages/skin/compoment/skin";
import BoneDensity from "./pages/BoneDensity/compoment/boneDensity";
import VertebraeDensity from "./pages/VertebraeDensity/compoment/vertebraeDensity";
import UserCenter from "./pages/userCenter/component/userCenter";
import HistoryResult from "./pages/historyResult/component/historyResult";

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  const routeComponent = (props) => {
    return isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/login` }} />
    );
  };
  return <Route render={routeComponent} {...rest} />;
};
const priList: any = [
  { url: "Confluency", com: CellFusion },
  { url: "JointInflammation", com: BoneJoints },
  { url: "SkinInjury", com: Skin },
  { url: "BoneDensity", com: BoneDensity },
  { url: "VertebraeDensity", com: VertebraeDensity },
  { url: "userCenter", com: UserCenter },
  { url: "historyResult", com: HistoryResult },
];
const App = () => {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/routepages`}
            component={RoutePages}
          />
          <Route path={`${process.env.PUBLIC_URL}/home`} component={Home} />
          {priList.map((x) => {
            return (
              <PrivateRoute
                isAuthenticated={localStorage.getItem("BeadrToken") !== null}
                path={`${process.env.PUBLIC_URL}/home/${x.url}`}
                component={x.com}
              />
            );
          })}

          <Route
            path={`${process.env.PUBLIC_URL}/register`}
            component={Register}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/forgetPsd`}
            component={ForgetPsd}
          />
          <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          {/* <Redirect path="/" to={`${process.env.PUBLIC_URL}/routepages`} /> */}
          {/* <Route path={`/`} component={NoFound} /> */}
          <Route component={NoFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
