import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getResultList = createAsyncThunk(
  "/task/search",
  async (paramaters: { current: number; size: number }, thunkAPI) => {
    const { data } = await axios.get(
      `/task/search?current=${paramaters.current}&size=${paramaters.size}`
    );
    return data.data;
  }
);
interface historyResultState {
  resultList: any[];
  total: number;
  size: number;
  current: number;
}
const initialState: historyResultState = {
  resultList: [],
  total: 0,
  size: 10,
  current: 1,
};
export const historyResultSlice = createSlice({
  name: "historyResult",
  initialState,
  reducers: {
    token: (s, a) => {
      // s.token = a.payload;
    },
  },
  extraReducers: {
    [getResultList.pending.type]: (state) => {},
    [getResultList.fulfilled.type]: (s, a) => {
      s.resultList = a.payload.records;
      s.total = a.payload.total;
      s.size = a.payload.size;
      s.current = a.payload.current;
    },
    [getResultList.rejected.type]: (
      state,
      action: PayloadAction<string | null>
    ) => {},
  },
});
