import { FC } from "react";

import confluency from "../../../../public/assest/confluency.png";
import bones from "../../../../public/assest/bones.png";
import skininhury from "../../../../public/assest/skininhury.png";
import bonedensity from "../../../../public/assest/bonedensity.png";
import yaozhui from "../../../../public/assest/yaozhui.png";
import cartilagethickness from "../../../../public/assest/cartilagethickness.png";
import styles from "./projectItem.module.css";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const ProjectItem: FC = () => {
  const { t } = useTranslation();
  const arr = [
    {
      name: `${t("homePage.Degree_of_cell_fusion")}`,
      enName: `${t("homePage.Degree_of_cell_fusionname")}`,
      detail: `${t("homePage.Degree_of_cell_fusionDetail")}`,
      path: "/home/Confluency",
      img: `${confluency}`,
    },
    {
      name: `${t("homePage.Evaluation_of_osteoarthrosis")}`,
      enName: `${t("homePage.Evaluation_of_osteoarthrosisname")}`,
      detail: `${t("homePage.Evaluation_of_osteoarthrosisdetail")}`,
      path: "/home/JointInflammation",
      img: `${bones}`,
    },
    {
      name: `${t("homePage.fs3")}`,
      enName: `${t("homePage.fs3en")}`,
      detail: `${t("homePage.fs3detail")}`,
      path: "/home/SkinInjury",
      img: `${skininhury}`,
    },
    {
      name: `${t("homePage.fs7")}`,
      enName: `${t("homePage.fs7en")}`,
      detail: `${t("homePage.fs7detail")}`,
      path: "/home/BoneDensity",
      img: `${bonedensity}`,
    },
    {
      name: `${t("homePage.fs8")}`,
      enName: `${t("homePage.fs8en")}`,
      detail: `${t("homePage.fs8detail")}`,
      path: "/home/VertebraeDensity",
      img: `${yaozhui}`,
    },
    {
      name: `${t("homePage.fs9")}`,
      enName: `${t("homePage.fs9en")}`,
      detail: `${t("homePage.fs9detail")}`,
      path: "/home/CartilageThickness",
      img: `${cartilagethickness}`,
    },
    // {
    //   name: `${t("homePage.fs4")}`,
    //   enName: `${t("homePage.fs4en")}`,
    //   detail: `${t("homePage.fs4detail")}`,
    //   path: "/home/IL6Detection",
    //   img: `${il6}`,
    // },
    // {
    //   name: `${t("homePage.fs5")}`,
    //   enName: `${t("homePage.fs5en")}`,
    //   detail: `${t("homePage.fs5detail")}`,
    //   path: "/home/TunelDetection",
    //   img: `${Tunel}`,
    // },
    // {
    //   name: `${t("homePage.fs6")}`,
    //   enName: `${t("homePage.fs6en")}`,
    //   detail: `${t("homePage.fs6detail")}`,
    //   path: "/home/MassonDetection",
    //   img: `${Masson}`,
    // },
  ];
  const history = useHistory();
  return (
    <div className={styles.item}>
      {arr.map((x, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              console.log(x, index);

              history.push(`${process.env.PUBLIC_URL}${x.path}`);
              // : message.warning("此功能正在开发中 敬请期待");
            }}
            className={styles.items}
          >
            <div className={styles.imgBox}>
              <img className={styles.projectItemImg} src={x.img} alt="" />
            </div>
            <div className={styles.Content}>
              <div>
                <div className={styles.contentTitle}>{x.name}</div>
                <div className={styles.contentEn}>{x.enName}</div>
              </div>
              <div className={styles.contentDes}>{x.detail}</div>
            </div>
          </div>
        );
      })}
      <div className={styles.buttom}>
        <div className={styles.moreBtn}>{t("homePage.moreBtn")}</div>
      </div>
    </div>
  );
};

export default ProjectItem;
