import { FC, useEffect, useState } from "react";

import styles from "./register.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { Input } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
import footer from "../../../../public/assest/footer.png";
import { registerSlice, getQcord } from "../../../redux/register/slice";
import { message, Statistic } from "antd";

import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Form, Input, Row, Col, Checkbox, Button } from "antd";

import axios from "axios";
import dayjs from "dayjs";
import { toBackendToken } from "../../../utils/toBackendToken";
import { getCodeSlice } from "../../../redux/getCode/slice";
import { useSelector } from "../../../redux/hooks";

const Register: FC = () => {
  const { Countdown } = Statistic;
  const history = useHistory();
  const dispatch = useDispatch();
  //   const goLogin = () => {};
  useEffect(() => {
    localStorage.clear();
    dispatch(getCodeSlice.actions.showGetCode(true));
  }, [dispatch]);
  const Demo = () => {
    const timeOut = () => {
      console.log("finished!");
      dispatch(getCodeSlice.actions.showGetCode(true));
    };
    const [agree, setAgree] = useState<boolean>(false);
    const [agreeCheckbox, setAgreeCheckbox] = useState<boolean>(false);
    const { showGetCode } = useSelector((s) => s.getCodeSlice);
    const deadline = Date.now() + 60000; // Moment is also OK
    let reg_tel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
      console.log("Success:", values);
      let value = form.getFieldsValue();
      if (agreeCheckbox) {
        of(value)
          .pipe(
            switchMap(() =>
              axios.post(`/user/register`, {
                checkCode: value.qcord,
                email: value.email,
                mobile: value.phone,
                organizationName: value.institution,
                password: value.password,
                userName: value.username,
              })
            )
          )
          .toPromise()
          .then((data) => {
            let res = data?.data;
            if (res.code === "200") {
              message.success("注册成功");
              history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
              message.error(res.operation.data["content"]);
            }
          });
      } else {
        setAgree(!agree);
        setTimeout(() => {
          setAgree(false);
          console.log("2");
        }, 1000);
      }
    };
    const getCord = () => {
      const codeToken = toBackendToken({
        time: dayjs().valueOf(),
      });
      dispatch(registerSlice.actions.registerData("222222222"));
      if (reg_tel.test(form.getFieldValue("phone"))) {
        dispatch(
          getQcord({
            phoneAndCode: codeToken,
            targetNum: form.getFieldValue("phone"),
            msgType: "register",
          })
        );
        dispatch(getCodeSlice.actions.showGetCode(false));
      } else {
        message.warning("请输入正确的手机号");
      }
    };
    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Form
        form={form}
        style={{ width: 330, position: "relative" }}
        name="basic"
        initialValues={{
          remember: false,
          prefix: "86",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={styles.formLogo}>
          <img src={footer} alt="" />
        </div>

        <div className={styles.formTitle}>新用户注册</div>

        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="username"
          rules={[{ required: true, message: "请输入您的用户名" }]}
        >
          <Input placeholder="用户名" />
        </Form.Item>

        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input placeholder="邮箱" />
        </Form.Item>

        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="password"
          rules={[
            { required: true, message: "请设置密码" },
            { min: 6, max: 16, message: "6 - 16 位密码，区分大小写" },
          ]}
        >
          <Input type="password" placeholder="密码" />
        </Form.Item>

        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码不一致！"));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="确认密码" />
        </Form.Item>

        <Form.Item
          colon={false}
          label=" "
          style={{ width: "100%" }}
          name="phone"
          rules={[
            { required: true, message: "请输入11位手机号" },
            { min: 11, max: 11, message: "请输入11位手机号" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (reg_tel.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("请输入正确的手机号"));
              },
            }),
          ]}
        >
          <Input placeholder="11位手机号" />
        </Form.Item>

        <Form.Item>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                colon={false}
                label=" "
                name="qcord"
                rules={[
                  {
                    required: true,
                    message: "请输入正确的验证码!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button style={{ width: "100%" }}>
                {showGetCode ? (
                  <span onClick={getCord}> 获取验证码</span>
                ) : (
                  <Countdown
                    value={deadline}
                    onFinish={timeOut}
                    format=" s "
                    valueStyle={{
                      width: "100%",
                      fontSize: 15,
                    }}
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          style={{ width: "100%", paddingLeft: 20, marginTop: -25 }}
          name="institution"
        >
          <Input placeholder="机构名称" />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          style={{ display: "flex" }}
        >
          <Checkbox
            onClick={() => {
              setAgreeCheckbox(!agreeCheckbox);
            }}
            className={agree ? styles.uesereules : ""}
          >
            {" "}
            <span
              onClick={() => {
                window.open(`${process.env.PUBLIC_URL}/home/userAgreement`);
              }}
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {" "}
              我已阅读并同意《用户协议》
            </span>
          </Checkbox>
          <span
            style={{ cursor: "pointer", color: "#1890ff" }}
            className={styles.login}
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/login`);
            }}
          >
            使用已有账户登录
          </span>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          {" "}
          <Button
            className={styles.register}
            style={{
              background: "#006fff",
              width: "100%",
            }}
            type="primary"
            htmlType="submit"
          >
            注册
          </Button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <div className={styles.page}>
      <div className={styles.form}>
        <Demo />
      </div>
    </div>
  );
};

export default Register;
