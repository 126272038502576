import { createFromIconfontCN } from "@ant-design/icons";

export const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2159981_0nf3iev89g.js",
});

// interface ExtraProps {
//   size?: number | string;
//   color?: string;
// }

// export const Icon = styled(IconBase)<ExtraProps>`
//   font-size: ${(p) => {
//     switch (typeof p.size) {
//       case "number":
//         return `${p.size}px`;
//       case "string":
//         return `${p.size}`;
//       default:
//         return `12px`;
//     }
//   }};
//   color: ${(p) => p.color || "#000000"};
// ` as FC<ExtraProps & Omit<IconFontProps, "size">>;
