import dayjs from "dayjs";

export enum TimeFormat {
  /**
   * 年月日格式
   */
  YMD = "YYYY-MM-DD",
  /**
   * 年月日时分秒
   */
  YMDHM = "YYYY-MM-DD HH:mm",
}

/**
 * 时间格式化
 * 默认格式为YYYY-MM-DD HH:mm
 * @param time
 */

export const formatTime = (
  time?: number | undefined | string,
  formatter: string | TimeFormat = TimeFormat.YMDHM
) => (time ? dayjs(time).format(formatter) : "");

/**
 * 时间转换为时间戳
 * @param time
 */
export const timeToNumber = (time: string) => dayjs(time).valueOf();

/**
 * 时间选择开始
 * @params time
 */
export const timeSelectStart = (time: string) =>
  dayjs(time).startOf("day").valueOf();

/**
 * 时间选择结束
 * @params time
 */
export const timeSelectEnd = (time: string) =>
  dayjs(time).endOf("day").valueOf();
