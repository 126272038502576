import { FC, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { userSlice } from "../../../redux/login/slice";
import { useSelector } from "../../../redux/hooks";
import { Dropdown, } from "antd";
import { useHistory } from "react-router";
import { Layout, Menu } from "antd";
import styles from "./header.module.css";

import logo from "../../../../public/assest/logo.png";
import mayixuejie from "../../../../public/assest/userava.png";
import usercenter from "../../../../public/assest/usercenter.png";
import loginout from "../../../../public/assest/loginout.png";
import historyresult from "../../../../public/assest/historyresult.png";
import jwt_decode, { JwtPayload as defaultJwt } from "jwt-decode";
import { HeaderSlice } from "../../../redux/header/slice";
import { useTranslation } from "react-i18next";
import { DownOutlined, } from "@ant-design/icons";
import NewTooltip from "./newTooltip";
interface JwtPayload extends defaultJwt {
  id: any;
}

const Header: FC = () => {
  const { t } = useTranslation();
  const { token } = useSelector((s) => s.user);
  const { languageCode } = useSelector((s) => s.HeaderSlice);
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { Header } = Layout;
  const [chooseWhichItem, setChooseWhichItem] = useState<any>('')
  const [chooseWhichItemDes, setChooseWhichItemDes] = useState<any>('')
  const [chooseWhichItemKey, setChooseWhichItemKey] = useState<any>(1)
  const [hasEnterMenu, setHasEnterMenu] = useState<boolean>(false)
  // 导航条
  const menuList: object[] = [
    { menuName: `${t("header.gugexitong")}`, path: "Welcome", key: 1, des: `${t("header.gugexitongDes")}` },
    { menuName: `${t("header.speciaFeel")}`, path: "Welcome", key: 2, des: `${t("header.speciaFeelDes")}` },
    { menuName: `${t("header.other")}`, path: "Welcome", key: 3, des: `${t("header.otherDes")}` },
    // { menuName: `${t("header.page1")}`, path: "page2" },
    // { menuName: `${t("header.page2")}`, path: "page3" },
  ];
  // 肌肉骨骼系统
  const moreRightGuge = [
    {
      name: `${t("header.gu")}`, children: [{
        path: "/home/BoneDensity",
        childrenName: `${t("header.gumidu-jingu")}`
      }, {
        path: "/home/VertebraeDensity",
        childrenName: `${t("header.gumidu-yaozhui")}`
      }, { path: `/home/JointInflammation`, childrenName: `${t("header.guguanjieyanzheng")}` }]
    },
    {
      name: `${t("header.ruanGu")}`, children: [{
        path: "/home/CartilageThickness",
        childrenName: `${t("header.ruanguhoudu")}`
      }, { childrenName: `${t("header.ruangudanbaijutanghanliang")}` }, { childrenName: `${t("header.guanjieruanguchenggujiexiandewanquchengdu")}` }, { childrenName: `${t("header.jinruanguxueguanshuliangjimianji")}` }, { childrenName: `${t("header.ruanguxibaoshuliang")}` }, { childrenName: `${t("header.guanjieguxiaoliangmianji")}` }]
    },
    { name: `${t("header.guGeJi")}`, children: [] }
  ]
  // 特殊感受器
  const moreRightSpecial = [
    { name: `${t("header.pifu")}`, children: [{ path: "/home/SkinInjury", childrenName: `${t("header.biaopisunshang")}` }] },
    { name: `${t("header.yan")}`, children: [] },
    { name: `${t("header.er")}`, children: [] }
  ]
  //  其他
  const moreRightOther = [
    { name: `${t("header.other")}`, children: [{ path: '/home/Confluency', childrenName: `${t("header.xibaorongheduyujishu")}` }] },
  ]
  useEffect(() => {
    if (token) {
      const decodeToken = jwt_decode<JwtPayload>(token);
      console.log(decodeToken);

      setUserId(decodeToken["uid"]);
    }
  }, [token]);
  const menu = (
    <Menu style={{ borderRadius: 10, overflow: "hidden", paddingRight: 10 }}>
      <Menu.Item
        key="1"
        onClick={() => {
          history.push(`${process.env.PUBLIC_URL}/home/userCenter`);
        }}
      >
        <img src={usercenter} alt="" /> {t("header.usercenter")}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          history.push(`${process.env.PUBLIC_URL}/home/historyResult`);
        }}
      >
        <img src={historyresult} alt="" /> {t("header.history")}
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          localStorage.clear();
          dispatch(userSlice.actions.token(""));
          setUserId("");
          history.push(`${process.env.PUBLIC_URL}/routepages/Welcome`);
          window.location.reload();
        }}
      >
        <img src={loginout} alt="" /> {t("header.loginOut")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        height: 64,
      }} id="routePageHeader" className={styles.layout}>
      <Layout
        className={styles.superLayout}
      >
        <Header
          style={{
            minWidth: "1200px",
            margin: "0 auto",
            background: "#006FFF",
            display: "flex",
          }}
        >
          <div onClick={() => { history.push(`${process.env.PUBLIC_URL}/routepages/Welcome`); }}
            className={styles.logo}>
            <img className={styles.logoImg} src={logo} alt="" />
          </div>

          <Menu
            onMouseLeave={() => {
              setHasEnterMenu(false)
            }}
            theme="dark"
            mode="horizontal"
            className={styles.menuAll}

            style={{
              background: "#006FFF",
              color: "#fff",
            }}
          >

            {menuList.map((x: any, index) => {
              const key = index + 1;
              return (
                <Menu.Item
                  style={{
                  }}
                  className={styles.menuItem}
                  key={key}
                >
                  <div
                    onMouseEnter={() => {
                      setChooseWhichItem(x["menuName"])
                      setChooseWhichItemDes(x.des)
                      setHasEnterMenu(true)
                      setChooseWhichItemKey(x.key)
                    }} className={styles.menuLists}  >
                    <div>
                      {x["menuName"]}
                    </div>
                    <div style={{ marginLeft: 8 }}>
                      <DownOutlined />
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
          <div onMouseLeave={() => {
            setHasEnterMenu(false)
          }}
            onMouseEnter={() => {
              setHasEnterMenu(true)
            }} className={hasEnterMenu ? styles.menuDetail : styles.menuDetailNoShow}>
            <div id="moreDetail" className={styles.moreDetail}>
              <div className={styles.moreDetailLeft}>
                <div className={styles.moreDetailLeftTitle}>{chooseWhichItem}</div>
                <div className={styles.moreDetailLeftDetail}>{chooseWhichItemDes}</div>
              </div>
              <div className={styles.moreDetailRight}>
                <NewTooltip languageCode={languageCode} chooseWhichItemKey={chooseWhichItemKey} moreRightGuge={moreRightGuge} moreRightSpecial={moreRightSpecial} moreRightOther={moreRightOther} />
              </div>
            </div>
          </div>
          <div className={styles.headerRight}>
            {userId ? (
              <div className={styles.loginAva}>
                <Dropdown overlay={menu}>
                  <img className={styles.ava} src={mayixuejie} alt="" />
                </Dropdown>
              </div>
            ) : (
              <div
                style={{ color: "#fff" }}
                onClick={() => {
                  window.open(`${process.env.PUBLIC_URL}/login`);
                }}
                className={styles.loginText}
              >
                {t("header.loginIn")}
              </div>
            )}
            <div
              onClick={() => {
                if (languageCode === "zh") {
                  dispatch(HeaderSlice.actions.changeLanguage("en"));
                } else {
                  dispatch(HeaderSlice.actions.changeLanguage("zh"));
                }
              }}
              className={styles.changeLanguageBox}
            >
              <div className={styles.changeLan}>
                {languageCode === "zh" ? "中文" : "English"}
              </div>

            </div>
          </div>
        </Header>
      </Layout>
    </div>
  );
};

export default Header;
