import { FC, useState, useRef, useEffect } from "react";
import styles from "./userCenter.module.css";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../redux/hooks";
import {
  getUserCenterInfo,
  changeUserName,
  changeOrganization,
  changePsd,
  userCenterSlice,
} from "../../../redux/userCenter/slice";
// import { Input } from "antd";
// import { SearchOutlined } from "@ant-design/icons";

import { Typography, Skeleton, message, Form } from "antd";

import FormDemo from "../../../component/from";
import { md5 } from "md5js";
import { useTranslation } from "react-i18next";

import { from, lastValueFrom, of, switchMap } from "rxjs";
import axios from "axios";
import { useHistory } from "react-router-dom";

// import axios from "axios";

// import { md5 } from "md5js";
const { Title } = Typography;

const UserCenter: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { languageCode } = useSelector((s) => s.HeaderSlice);
  useEffect(() => {
    if (localStorage.getItem("BeadrToken") === null) {
      history.push(`/`);
      window.open(`${process.env.PUBLIC_URL}/login`);
    }
  }, [history]);
  useEffect(() => {
    dispatch(getUserCenterInfo());
  }, [dispatch]);
  const {
    id,
    userName,
    mobile,
    email,

    organizationName,
  } = useSelector((s) => s.userCenter);
  const userInfo: any = [
    {
      type: "用户名",
      value: userName,
      show: false,
      name: `${t("userCenter.username")}`,
    },
    {
      type: "密码",
      value: null,
      show: false,
      name: `${t("userCenter.psd")}`,
    },
    {
      type: "邮箱",
      value: email,
      show: false,
      name: `${t("userCenter.email")}`,
    },
    {
      type: "手机号",
      value: mobile,
      show: false,
      name: `${t("userCenter.phone")}`,
    },
    {
      type: "机构",
      value: organizationName,
      show: false,
      name: `${t("userCenter.org")}`,
    },
  ];

  const ListLabel = ({ name, type, value, key, show }) => {
    const formRef = useRef<any>();
    const [form] = Form.useForm();
    const [change, setChange] = useState<boolean>(show);
    const changeWhich = (type: any) => {
      form
        .validateFields()
        .then((values) => {
          console.log(values);
          // form.resetFields();
          if (type === "用户名") {
            let { username } = values;
            dispatch(changeUserName({ userName: username }));
            setChange(false);
          } else if (type === "密码") {
            let { initPassword, password } = values;
            if (initPassword || password) {
              dispatch(
                changePsd({
                  newPassword: password,
                  oldPassword: md5(initPassword, 32).toUpperCase(),
                })
              );
              setChange(false);
            }
          } else if (type === "邮箱") {
            let data = values.email;
            console.log(data);
          } else if (type === "手机号") {
            let { initPhone, initPhoneQcord, phone, phoneQcord } = values;
            const bindNewPPhone = async () => {
              await lastValueFrom(
                of(phoneQcord, phone).pipe(
                  switchMap(() => {
                    return from(
                      axios.post(`/user/verify-code/bind-new-phone`, {
                        checkCode: phoneQcord,
                        msgType: "bindNewPhone",
                        phone: phone,
                      })
                    );
                  })
                )
              ).then((data) => {
                let res = data.data;
                if (res.code === "100") {
                  message.error(`${res.operation.data.content}`);
                  console.log("2222222222");
                } else if (res.code === "200") {
                  console.log(res);
                  dispatch(userCenterSlice.actions.mobile(phone));
                  setChange(false);
                }
              });
            };
            const checkInitCode = async (initPhoneQcord: any) => {
              await lastValueFrom(
                of(initPhoneQcord).pipe(
                  switchMap((initPhoneQcord) => {
                    return from(
                      axios.get(
                        `/user/verify-code/change-phone?checkCode=${initPhoneQcord}`
                      )
                    );
                  })
                )
              ).then((data) => {
                let res = data.data;
                if (res.code === "100") {
                  message.error(`${res.operation.data.content}`);
                } else if (res.code === "200") {
                  console.log("下一步");
                  bindNewPPhone();
                }
              });
            };

            if (initPhone || initPhoneQcord || phone || phoneQcord) {
              checkInitCode(initPhoneQcord);
            } else {
              message.error("请输入完整信息");
            }
          } else if (type === "机构") {
            let { institution } = values;
            dispatch(changeOrganization({ organization: institution }));
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    };

    return (
      <div className={styles.listLabel}>
        <div className={styles.listLabelLeft}>
          <div className={styles.label}>
            {name}
            {languageCode === "zh" ? "：" : ": "}
          </div>
          <div className={styles.content}>
            {change ? (
              <>
                <FormDemo type={type} ref={formRef} form={form} />
              </>
            ) : (
              <> {type === "密码" ? "********" : value}</>
            )}
          </div>
        </div>

        {type === "用户ID" ? (
          <></>
        ) : (
          <div key={key} className={styles.listLabelRight}>
            {change ? (
              <>
                <div
                  onClick={() => {
                    setChange(false);
                    form.resetFields();
                  }}
                  className={styles.cancle}
                >
                  {t("userCenter.cancle")}
                </div>
                <div
                  onClick={() => {
                    console.log(type);
                    changeWhich(type);
                  }}
                  className={styles.ok}
                >
                  {t("userCenter.submit")}
                </div>
              </>
            ) : (
              <div
                onClick={() => {
                  setChange(true);
                  console.log("type", type);
                }}
                className={styles.change}
              >
                {type === "邮箱" ? null : t("userCenter.eidt")}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {id === -1 ? (
        <Skeleton />
      ) : (
        <div className={styles.page}>
          <Title style={{ letterSpacing: 1 }} level={2}>
            {t("header.usercenter")}
          </Title>
          {userInfo.map((x, index) => {
            return (
              <ListLabel
                show={x.show}
                key={index}
                type={x.type}
                value={x.value}
                name={x.name}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default UserCenter;
