import React from 'react';
import { Tooltip } from 'antd';
import styles from "./header.module.css";
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from "react-router";

interface tooltipinterface {
    languageCode: any,
    chooseWhichItemKey: any,
    moreRightGuge: any,
    moreRightSpecial: any,
    moreRightOther: any
}
const zhLength = 10
const enLength = 20
const NewTooltip: React.FC<tooltipinterface> = ({ moreRightGuge, moreRightSpecial, moreRightOther, languageCode, chooseWhichItemKey }) => {
    const history = useHistory();

    return (

        <>


            {chooseWhichItemKey === 1 && <>
                {moreRightGuge?.map((x) => {
                    return <div className={styles.moreRightGugeBox}>
                        <div className={styles.moreRightGuge}>{x.name}</div>
                        <div className={styles.moreRightGugeItemBox}>
                            {x?.children.map((y) => {
                                return <div

                                    onClick={() => {

                                        y.path && history.push(`${process.env.PUBLIC_URL}${y.path}`);
                                    }}
                                    className={styles.moreRightGugeItem}>
                                    <div className={styles.moreRightGugeItemChildrenName}>

                                        {languageCode === 'zh' ? <>
                                            {y.childrenName.length > zhLength ? <Tooltip
                                                color='#fff'
                                                placement={'bottom'}
                                                arrow={false}
                                                // @ts-ignore
                                                getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}
                                                overlayInnerStyle={{
                                                    color: '#666666',
                                                    wordWrap: 'normal'

                                                }}
                                                title={y.childrenName}>
                                                <span>{y.childrenName.substring(0, zhLength)}...</span>
                                            </Tooltip> : <>       {y.childrenName}</>}

                                        </> : <>


                                            {y.childrenName.length > enLength ? <Tooltip


                                                color='#fff'
                                                placement={'bottom'}
                                                arrow={false}
                                                // @ts-ignore

                                                getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}
                                                overlayInnerStyle={{



                                                    color: '#666666'
                                                }} title={y.childrenName}>
                                                <span>{y.childrenName.substring(0, enLength)}...</span>
                                            </Tooltip> : <>       {y.childrenName}</>}
                                        </>}




                                    </div>
                                    <div className={styles.moreRightGugeItemRightIcon}> <RightOutlined /></div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </>}
            {chooseWhichItemKey === 2 && <>
                {moreRightSpecial?.map((x) => {
                    return <div className={styles.moreRightGugeBox}>
                        <div className={styles.moreRightGuge}>{x.name}</div>
                        <div className={styles.moreRightGugeItemBox}>{x?.children.map((y) => {
                            return <div

                                onClick={() => {
                                    y.path && history.push(`${process.env.PUBLIC_URL}${y.path}`);
                                }}
                                className={styles.moreRightGugeItem}>
                                <div className={styles.moreRightGugeItemChildrenName}>  {languageCode === 'zh' ? <>

                                    {y.childrenName.length > zhLength ? <Tooltip color='#fff'
                                        placement={'bottom'}
                                        arrow={false}
                                        // @ts-ignore

                                        getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}

                                        overlayInnerStyle={{
                                            wordWrap: 'normal',

                                            color: '#666666'
                                        }} title={y.childrenName}>
                                        <span>{y.childrenName.substring(0, zhLength)}...</span>
                                    </Tooltip> : <>       {y.childrenName}</>}

                                </> : <>


                                    {y.childrenName.length > enLength ? <Tooltip color='#fff'
                                        placement={'bottom'}
                                        arrow={false}
                                        // @ts-ignore

                                        getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}

                                        overlayInnerStyle={{
                                            wordWrap: 'normal',

                                            color: '#666666'
                                        }} title={y.childrenName}>
                                        <span>{y.childrenName.substring(0, enLength)}...</span>
                                    </Tooltip> : <>       {y.childrenName}</>}
                                </>}

                                </div>
                                <div className={styles.moreRightGugeItemRightIcon}> <RightOutlined /></div>
                            </div>
                        })}</div>
                    </div>
                })}
            </>}
            {chooseWhichItemKey === 3 && <>
                {moreRightOther?.map((x) => {
                    return <div className={styles.moreRightGugeBox}>
                        <div className={styles.moreRightGuge}>{x.name}</div>
                        <div className={styles.moreRightGugeItemBox}>{x?.children.map((y) => {
                            return <div

                                onClick={() => {
                                    y.path && history.push(`${process.env.PUBLIC_URL}${y.path}`);
                                }}
                                className={styles.moreRightGugeItem}>
                                <div className={styles.moreRightGugeItemChildrenName}>   {languageCode === 'zh' ? <>

                                    {y.childrenName.length > zhLength ? <Tooltip color='#fff'
                                        placement={'bottom'}
                                        arrow={false}
                                        // @ts-ignore

                                        getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}

                                        overlayInnerStyle={{
                                            wordWrap: 'normal',

                                            color: '#666666'
                                        }} title={y.childrenName}>
                                        <span>{y.childrenName.substring(0, zhLength)}...</span>
                                    </Tooltip> : <>       {y.childrenName}</>}

                                </> : <>


                                    {y.childrenName.length > enLength ? <Tooltip color='#fff'
                                        placement={'bottom'}
                                        arrow={false}
                                        // @ts-ignore

                                        getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode}

                                        overlayInnerStyle={{
                                            wordWrap: 'normal',

                                            color: '#666666'
                                        }} title={y.childrenName}>
                                        <span>{y.childrenName.substring(0, enLength)}...</span>
                                    </Tooltip> : <>       {y.childrenName}</>}
                                </>}

                                </div>
                                <div className={styles.moreRightGugeItemRightIcon}> <RightOutlined /></div>
                            </div>
                        })}</div>
                    </div>
                })}
            </>}



        </>

    );

}

export default NewTooltip;