import { createSlice } from "@reduxjs/toolkit";

import i18n from "i18next";
// import cookie from "react-cookies";
interface UserCenterState {
  languageCode: any;
}

const initialState: UserCenterState = {
  languageCode: "zh",
};

export const HeaderSlice = createSlice({
  name: "HeaderSlice",
  initialState,
  reducers: {
    changeLanguage: (s, a) => {
      s.languageCode = a.payload;
      i18n.changeLanguage(a.payload);
    },
    token: (s, a) => {
      // s.token = a.payload;
    },
  },
});
