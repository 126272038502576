import { FC } from "react";

import styles from "./welcome.module.css";
import { Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import ProjectItem from "../../../component/projectItem";

import enback from "../../../../public/assest/enback.png";

import { useTranslation } from "react-i18next";
const Welcome: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <div className={styles.page}>
      <Content
        style={{
          minWidth: "1200px",
          margin: "0 auto",
          background: "rgb(251, 251, 251)",
        }}
      >
        <div className={styles.backgroundBox}>
          <div className={styles.titleBox}>
            {" "}
            <Title className={styles.backgroundTitle}>
              {t("footer.title")}
            </Title>
            <Title level={5} className={styles.backgroundDes}>
              {t("footer.des")}
            </Title>
          </div>

          <img className={styles.background} src={enback} alt="" />
        </div>
      </Content>
      <Content
        style={{
          width: "100%",
          background: "rgb(251, 251, 251)",
        }}
      >
        <div
          style={{
            width: "1200px",
            margin: "0 auto",
            padding: "100px 45px 0",
            background: "rgb(251, 251, 251)",
          }}
        >
          <ProjectItem />
        </div>
      </Content>
    </div>
  );
};

export default Welcome;
