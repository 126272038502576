import { userSlice } from "./login/slice";
import { registerSlice } from "./register/slice";
import { userCenterSlice } from "./userCenter/slice";
import { HeaderSlice } from "./header/slice";
import { imgFcSlice } from "./imgFunction/slice";
import { getCodeSlice } from "./getCode/slice";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { historyResultSlice } from "./historyResult/slice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const rootReducer = combineReducers({
  user: userSlice.reducer,
  register: registerSlice.reducer,
  userCenter: userCenterSlice.reducer,
  historyResult: historyResultSlice.reducer,
  HeaderSlice: HeaderSlice.reducer,
  imgFcSlice: imgFcSlice.reducer,
  getCodeSlice: getCodeSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
  devTools: true,
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
//eslint-disable-next-line
export default { store, persistor };
