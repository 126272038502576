import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import axios from "axios";
import BASE_URL from "../../utils/gobalData";
// import cookie from "react-cookies";
interface UserCenterState {
  id: number;
  userName: string;
  mobile: string;
  email: string;
  emailStatus: string;
  organizationName: string;
  headIcon: any;
}

const initialState: UserCenterState = {
  id: -1,
  userName: "",
  mobile: "",
  email: "",
  emailStatus: "",
  organizationName: "",
  headIcon: "",
};

export const getUserCenterInfo = createAsyncThunk(
  "userCenter/personal",
  async (thunkAPI) => {
    const { data } = await axios.get(`/user-personal`);
    return data.data;
  }
);
export const changeUserName = createAsyncThunk(
  "userCenter//update-user-name",
  async (
    paramaters: {
      userName: any;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(
      `${BASE_URL}/user-personal/update-user-name`,
      {
        userName: paramaters.userName,
      }
    );
    return data.data;
  }
);
export const changeOrganization = createAsyncThunk(
  "userCenter/user-personal",
  async (
    paramaters: {
      organization: string;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(
      `${BASE_URL}/user-personal/update-user-name`,
      {
        organization: paramaters.organization,
      }
    );
    return data.data;
  }
);
export const changePsd = createAsyncThunk(
  "user/reset-pwd/personal",
  async (
    paramaters: {
      newPassword: string;
      oldPassword: string;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/user/reset-pwd/personal`, {
      newPassword: paramaters.newPassword,
      oldPassword: paramaters.oldPassword,
    });
    return data.data;
  }
);

export const changePhone = createAsyncThunk(
  "user/reset-pwd/personal",
  async (
    paramaters: { checkCode: string; msgType: string; phone: string },
    thunkAPI
  ) => {
    const { data } = await axios.post(`/user/verify-code/bind-new-phone`, {
      checkCode: paramaters.checkCode,
      msgType: paramaters.msgType,
      phone: paramaters.phone,
    });
    return data.data;
  }
);
export const userCenterSlice = createSlice({
  name: "userCenter",
  initialState,
  reducers: {
    token: (s, a) => {
      // s.token = a.payload;
    },
    mobile: (s, a) => {
      s.mobile = a.payload;
    },
  },
  extraReducers: {
    [getUserCenterInfo.pending.type]: (state) => {},
    [getUserCenterInfo.fulfilled.type]: (s, a) => {
      console.log(a);

      s.id = a.payload.id;
      s.email = a.payload.email;
      s.emailStatus = a.payload.emailStatus;
      s.headIcon = a.payload.headIcon;
      s.mobile = a.payload.mobile;
      s.organizationName = a.payload.organizationName;
      s.userName = a.payload.userName;
    },
    [getUserCenterInfo.rejected.type]: (state, action) => {
      console.log(action);
    },
    [changeUserName.fulfilled.type]: (s, a) => {
      // console.log(data);
      s.userName = a.payload.userName;
      message.success("修改成功");
    },
    [changeOrganization.fulfilled.type]: (s, a) => {
      // console.log(data);
      s.organizationName = a.payload.organizationName;
      message.success("修改成功");
    },
    [changePsd.fulfilled.type]: (s, a) => {
      message.success("修改成功");
    },
    // [changePhone.rejected.type]: (
    //   state,
    //   action: PayloadAction<string | null>
    // ) => {
    //   message.error("2222");
    //   console.log(action.payload);
    // },
    // [changePhone.fulfilled.type]: (s, a) => {
    //   message.success("修改成功");
    //   s.mobile = a.payload.mobile;
    // },
  },
});
