import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "antd/dist/antd.css";
import { Provider } from "react-redux";
import rootStore from "./redux/store";
import BASE_URL from "./utils/gobalData";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import enUS from "antd/lib/locale/en_US";

import { ConfigProvider, message } from "antd";
import dayjs from "dayjs";
import "./i18n/configs";
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
  "BeadrToken"
)} `;

console.log("loginTime", Number(localStorage.getItem("loginTime")));
console.log("nowTime", dayjs().valueOf());

axios.interceptors.request.use(
  (config) => {
    if (
      Number(localStorage.getItem("loginTime")) > 0 &&
      dayjs().valueOf() > Number(localStorage.getItem("loginTime"))
    ) {
      message.error("当前登录状态已过期,请重新登录");
      setTimeout(() => {
        localStorage.clear();
        window.close();
        window.open(`${process.env.PUBLIC_URL}/login`);
      }, 2000);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={rootStore.store}>
      <PersistGate persistor={rootStore.persistor}>
        <ConfigProvider locale={enUS}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
