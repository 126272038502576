import { FC, useState, useEffect } from "react";

import styles from "./forgetPsd.module.css";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { Input } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
import footer from "../../../../public/assest/footer.png";

import { Form, Input, Row, Col, Button, message, Statistic } from "antd";
import { getQcord, registerSlice } from "../../../redux/register/slice";
import { useDispatch } from "react-redux";
import { from, lastValueFrom, of, switchMap } from "rxjs";
import axios from "axios";
import { toBackendToken } from "../../../utils/toBackendToken";
import dayjs from "dayjs";
import { useSelector } from "../../../redux/hooks";
import { getCodeSlice } from "../../../redux/getCode/slice";

const Login: FC = () => {
  const dispatch = useDispatch();
  const [loginType, setLoginType] = useState<string>("account");
  useEffect(() => {
    localStorage.clear();
    dispatch(getCodeSlice.actions.showGetCode(true));
  }, [dispatch]);
  const history = useHistory();
  //   const dispatch = useDispatch();
  //   const goLogin = () => {};
  //   const userData = useSelector((s) => s.user);

  const Demo = () => {
    const { Countdown } = Statistic;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const deadline = Date.now() + 60000; // Moment is also OK
    const timeOut = () => {
      console.log("finished!");
      dispatch(getCodeSlice.actions.showGetCode(true));
    };
    const { showGetCode } = useSelector((s) => s.getCodeSlice);
    const onFinish = async (values: any) => {
      console.log("Success:", values);

      await lastValueFrom(
        of(values).pipe(
          switchMap((values) => {
            return from(
              axios.post(`/user/reset-pwd/phoneAndCode`, {
                checkCode: values.qcord,
                password: values.password,
                targetNum: values.phone,
              })
            );
          })
        )
      ).then((data) => {
        let res = data.data;
        if (res.code === "100") {
          message.error(`${res.operation.data.content}`);
        } else if (res.code === "200") {
          message.success("密码重置成功");
          history.push(`${process.env.PUBLIC_URL}/login`);
          window.location.reload();
        }
      });
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };
    let reg_tel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

    const getCord = () => {
      const codeToken = toBackendToken({
        time: dayjs().valueOf(),
      });
      dispatch(registerSlice.actions.registerData("222222222"));
      if (reg_tel.test(form.getFieldValue("phone"))) {
        dispatch(
          getQcord({
            phoneAndCode: codeToken,
            targetNum: form.getFieldValue("phone"),
            msgType: "resetPassword",
          })
        );
        dispatch(getCodeSlice.actions.showGetCode(false));
      } else {
        message.warning("请输入正确的手机号");
      }
    };

    return (
      <Form
        form={form}
        style={{ width: 330, position: "relative" }}
        name="basic"
        initialValues={{
          remember: false,

          prefix: "86",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={styles.formLogo}>
          <img src={footer} alt="" />
        </div>
        <div className={styles.loginType}>
          <div
            onClick={() => {
              setLoginType("account");
            }}
            className={
              loginType === "account"
                ? styles.loginOptionsSelected
                : styles.loginBase
            }
          >
            手机号找回
          </div>
          <div
            onClick={() => {
              setLoginType("phone");
            }}
            className={
              loginType === "phone"
                ? styles.loginOptionsSelected
                : styles.loginBase
            }
          >
            邮箱找回
          </div>
        </div>
        {loginType !== "account" ? (
          <>
            <Form.Item
              style={{ width: "100%" }}
              name="phone"
              rules={[{ required: true, message: "请输入注册的邮箱" }]}
            >
              <Input placeholder="请输入邮箱" />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              style={{ width: "100%" }}
              name="phone"
              rules={[
                { required: true, message: "请输入11位手机号" },
                { min: 11, max: 11, message: "您输入的手机号码不正确" },
              ]}
            >
              <Input placeholder="11位手机号" />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                name="qcord"
                rules={[
                  {
                    required: true,
                    message: "请输入验证码!",
                  },
                ]}
              >
                <Input placeholder="请输入验证码" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button style={{ width: "100%" }}>
                {showGetCode ? (
                  <span onClick={getCord}> 获取验证码</span>
                ) : (
                  <Countdown
                    value={deadline}
                    onFinish={timeOut}
                    format=" s "
                    valueStyle={{
                      width: "100%",
                      fontSize: 15,
                    }}
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          style={{ width: "100%", marginTop: -20 }}
          name="password"
          rules={[
            { required: true, message: "请设置密码" },
            { min: 6, max: 16, message: "6 - 16 位密码，区分大小写" },
          ]}
        >
          <Input type="password" placeholder="密码" />
        </Form.Item>

        <Form.Item
          style={{ width: "100%" }}
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码不一致！"));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="确认密码" />
        </Form.Item>

        <Button
          // className={styles.register}
          style={{
            background: "#006fff",
            width: "100%",
            marginBottom: 40,
          }}
          type="primary"
          htmlType="submit"
        >
          完成
        </Button>
      </Form>
    );
  };
  return (
    <div className={styles.page}>
      <div className={styles.form}>
        <Demo />
      </div>
    </div>
  );
};

export default Login;
