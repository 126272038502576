import { FC, } from "react";

import styles from "./routePages.module.css";

import { Route, Switch, useHistory } from "react-router-dom";

import { Layout } from "antd";

import Header from "../../../component/Header";

import Welcome from "../../welcome/component/welcome";
import Home from "../../home/component/home";
import NoFound from "../../errorPage/compoment/noFound";

import { useTranslation } from "react-i18next";

const RoutePages: FC = () => {
  const history = useHistory();
  const { Footer } = Layout;
  // const { t } = useTranslation();
  const { t } = useTranslation();

  return (
    <>
      <Layout className={styles["layout"]}>
        <Header />
        <div
          className={styles.routePagesHtml}>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL}/routepages/Welcome`}
              component={Welcome}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/routepages/home`}
              component={Home}
            />
            <Route component={NoFound} />
          </Switch>

          <Footer style={{ textAlign: "center" }}>
            {window.location.href.indexOf("/home/userAgreement") !== -1 ? (
              <></>
            ) : (
              <div className={styles.footer}>
                <div className={styles.footerTop}>
                  <div
                    onClick={() => {
                      history.push(
                        `${process.env.PUBLIC_URL}/home/userAgreement`
                      );
                    }}
                    className={styles.footer1}
                  >
                    {t("homePage.useragreement")}
                  </div>{" "}
                  <div
                    onClick={() => {
                      history.push(
                        `${process.env.PUBLIC_URL}/home/userAgreement`
                      );
                    }}
                    className={styles.footerTopCenter}
                  >
                    {t("homePage.Privacypolicy")}
                  </div>{" "}
                  <div
                    onClick={() => {
                      history.push(
                        `${process.env.PUBLIC_URL}/home/userAgreement`
                      );
                    }}
                    className={styles.footer1}
                  >
                    {t("homePage.callus")}
                  </div>
                </div>
                <div className={styles.footerBot}>
                  <div className={styles.footer1}>{t("homePage.copyright")}</div>{" "}
                  <div onClick={() => {
                    window.open(`https://beian.miit.gov.cn/#/Integrated/index`)
                  }} className={styles.footerTopCenter}>
                    {t("homePage.icp")}
                  </div>{" "}
                  <div onClick={() => {
                    window.open(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000677`)
                  }} className={styles.footer1}>{t("homePage.police")}</div>
                </div>
              </div>
            )}

            {/* <img className={styles.footerImg} src={footer} alt="" /> */}
          </Footer>
        </div>

      </Layout>
    </>
  );
};

export default RoutePages;
