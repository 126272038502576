import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import BASE_URL from "../../utils/gobalData";
// import cookie from "react-cookies";
interface UserState {
  show: boolean;
  error: any;
  token: any;
  codeValue: any;
}

const initialState: UserState = {
  show: false,
  error: "",
  token: "",
  codeValue: "",
};

export const login = createAsyncThunk(
  "user/login/accAndPwd",
  async (
    paramaters: {
      password: string;
      userName: string;
    },
    thunkAPI
  ) => {
    const { data } = await axios.post(`${BASE_URL}/user/login/accAndPwd`, {
      password: paramaters.password,
      userName: paramaters.userName,
    });
    return data;
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getTimeCountDown: (s, a) => {
      s.show = a.payload;
    },
    token: (s, a) => {
      s.token = a.payload;
    },
    error: (s, a) => {
      s.error = a.payload;
    },
    codeValue: (s, a) => {
      s.codeValue = a.payload;
    },
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      // state.loading = true;
    },
    [login.fulfilled.type]: (state, action) => {
      // localStorage.setItem("token", action.payload["token"]);
      // localStorage.setItem("cookie", action.payload["cookie"]);
      // localStorage.setItem("data", JSON.stringify(action.payload["profile"]));
      // state.data = action.payload;
      // state.loading = false;
      // state.error = null;

      console.log(action.payload);
    },
    [login.rejected.type]: (state, action: PayloadAction<string | null>) => {
      // state.loading = false;
      state.error = action.payload;
      console.log(action.payload);
    },
  },
});
